import update from 'immutability-helper';

import {
  UPDATE_COHORT_VALIDATION,
  RESET_COHORT_VALIDATION,
} from '../actions/types';

function initialiseState() {
  const result = {
    initialDiagnosis: false,
    patientID: false,
  };
  return result;
}

function cohortValidation(
  state = initialiseState(),
  action = {}) {
  // console.log('reducer validation', action);
  // console.log('state=', state);
  switch (action.type) {
    case UPDATE_COHORT_VALIDATION:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_COHORT_VALIDATION:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default cohortValidation;
