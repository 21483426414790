import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Mailto from '../utils/Mailto';
import { predictBloodEmail } from '../constants/Config';
import '../css/styles.css';

function ContactPage({ banner, headerBand }) {
  const predictBloodMailLink = (
    <Mailto
      email={predictBloodEmail}
      subject="MPN prognostic model"
    >
      {predictBloodEmail}
    </Mailto>
  );

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1>
          Contact
        </h1>
        <p className="ptext">
          Please address all queries to {predictBloodMailLink}
        </p>
      </Container>
      { banner }
    </div>
  );
}

ContactPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default ContactPage;
