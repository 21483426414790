import React from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../../css/styles.css';

function PrivacyPage({ banner, headerBand }) {
  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1>
          Site Privacy
        </h1>
        <p className="ptext">
          Information entered into the Predict Blood tool is never stored remotely.
          It is sent to a server to generate the results however it is not permanently recorded.
          Any information saved in the Gene Panel page is stored
          in the localStorage of your browser and does not leave your machine.
        </p>
      </Container>
      { banner }
    </div>
  );
}

PrivacyPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default PrivacyPage;
