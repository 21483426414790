import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import GridLabeledInput from './GridLabeledInput';
import ModalInformation from './ModalInformation';
import Border from './Border';

import ModalData from '../constants/HelpText';
import { borderColor } from '../constants/Config';

function PatientSelector({ label, patientIds, onChange, defaultValue, showBorder }) {
  const labelWidth = 4;
  const componentWidth = 8;

  // console.log('patientIds', patientIds);
  return (
    <GridLabeledInput
      help={<ModalInformation header={ModalData.CohortPatientID.Header} content={ModalData.CohortPatientID.Content} />}
      label={label}
      labelWidth={labelWidth}
      componentWidth={componentWidth}
      component={(
        <Border isVisible={showBorder} color={borderColor} classname="patientID">
          <Dropdown
            name="patientID"
            search
            selection
            options={patientIds}
            onChange={onChange}
            placeholder="Select Patient"
            value={defaultValue}
          />
        </Border>
      )}
    />
  );
}

PatientSelector.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  patientIds: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  showBorder: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PatientSelector;
