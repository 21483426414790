import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Button } from 'semantic-ui-react';

function ResponsiveMenuMobile({
  leftItems,
  onToggle,
  visible,
}) {
  return (
    <div>
      <Menu>
        <Menu.Item onClick={onToggle} position="right">
          <Button icon style={{ backgroundColor: '#2571d6', color: '#ffffff' }}>
            <Icon name="sidebar" size="large" />
          </Button>
        </Menu.Item>
      </Menu>
      { visible
        ? (
          <Menu stackable style={{ marginTop: '0px' }}>
            {leftItems}
          </Menu>
        ) : <div /> }
    </div>
  );
}

ResponsiveMenuMobile.propTypes = {
  leftItems: PropTypes.arrayOf(PropTypes.element).isRequired,
  onToggle: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ResponsiveMenuMobile;
