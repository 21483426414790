import React from 'react';
import { Input, Button, Divider, Segment, Message, Label } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class StoreGenePanel extends React.Component {
  constructor(props) {
    super(props);

    // Initial state for dropdown lists
    this.state = {
      name: '',
    };
  }

  handleChange = (e, { value }) => {
    this.setState(() => ({ name: value }));
  };

  render() {
    const { disabled, onClick, storedStatus } = this.props;
    const { name } = this.state;

    let message = '';
    let status = true;

    // Save button should be disabled if name is not set
    let disableButton = disabled;
    if (!disabled) {
      if (storedStatus) {
        if (storedStatus === 'exists') {
          message = 'Name already exists, please choose a different name';
          status = false;
        } else if (storedStatus === 'success') {
          message = 'Stored successfully';
        } else {
          message = 'Failed to store';
          status = false;
        }
      }
      if (!name) {
        disableButton = true;
      }
    }
    return (
      <Segment>
        <Input
          placeholder="name"
          disabled={disabled}
          onChange={this.handleChange}
          name="genePanelName"
        >
          <Label>Panel Name</Label>
          <input />
          <Label basic color="red">Required</Label>
        </Input>
        <div>
          <Divider hidden />
          <Button
            disabled={disableButton}
            content="Save"
            name="saveGenePanel"
            primary
            onClick={() => { onClick(name); }}
          />
          <br />
          <Message compact positive={status} negative={!status} hidden={!message}>{ message }</Message>
        </div>
      </Segment>
    );
  }
}

StoreGenePanel.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  storedStatus: PropTypes.string.isRequired,
};

export default StoreGenePanel;
