import React from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function GenePanelSelector({ list, onChange, value }) {
  return (
    <Input
      label="Select panel"
      fluid
      input={
        (
          <Dropdown
            placeholder="Select gene panel"
            fluid
            selection
            search
            options={list}
            onChange={onChange}
            value={value}
            style={{
              borderRadius: '0 4px 4px 0',
            }}
          />
        )
      }
    />
  );
}

GenePanelSelector.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(
    {
      key: PropTypes.string,
      value: PropTypes.string,
      text: PropTypes.string,
    },
  )).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

GenePanelSelector.defaultProps = {
  value: null,
};

export default GenePanelSelector;
