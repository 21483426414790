import React from 'react';
import { Button } from 'semantic-ui-react';
import Mailto from '../utils/Mailto';
import { predictBloodEmail, feedbackButton } from '../constants/Config';
import '../css/styles.css';

function FeedbackButton() {
  const { subject, body } = feedbackButton;
  const predictBloodMailLink = (
    <Mailto
      email={predictBloodEmail}
      subject={subject}
      body={body}
    >
      Feedback
    </Mailto>
  );

  return (
    <div>
      <Button id="feedback_vert">
        {predictBloodMailLink}
      </Button>
    </div>
  );
}

export default FeedbackButton;
