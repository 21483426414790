import React from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';
import { Message, Image, Divider } from 'semantic-ui-react';
import logo from '../images/Wellcome_Sanger_Institute_Logo_Landscape_Digital_RGB_Full_Colour.png';

import styles from '../css/styles.module.css';

function ReportHeader() {
  moment.locale('en-gb');
  const date = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <div className={styles.noshow}>
      <Image src={logo} size="small" />
      <Divider />
      <Message>
        Report created: {date}
        <br />
        Software version: {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION}
      </Message>
    </div>
  );
}

export default ReportHeader;
