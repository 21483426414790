import React from 'react';
import PropTypes from 'prop-types';

import '../css/incrementinput.css';
import Border from './Border';
import { borderColor } from '../constants/Config';

const textColorCheck = (amount, min, max) => (amount < min || amount > max ? 'red' : 'black');

class IncrementInput extends React.Component {
  handleChange = (e) => {
    e.preventDefault();
    const { setValue } = this.props;
    setValue(e, parseFloat(e.target.value));
  };

  IncrementItem = (e) => {
    const { setValue, value, step } = this.props;
    setValue(e, value + step);
  };

  DecreaseItem = (e) => {
    const { setValue, value, step } = this.props;
    setValue(e, value - step);
  };

  render() {
    const { minValue, maxValue, step, value, showBorder, classname } = this.props;

    // Convert default NaN to an empty string for displaying
    const displayedValue = Number.isNaN(value) ? '' : value;
    return (
      <div className="ui input">
        <Border isVisible={showBorder} color={borderColor} classname={classname}>
          <button className="left ui button" type="button" disabled={value <= minValue} onClick={this.DecreaseItem}>
            -
          </button>
          <input type="number" value={displayedValue} step={step} onChange={this.handleChange} style={{ width: 75, marginRight: 0, color: `${textColorCheck(value, minValue, maxValue)}` }} />
          <button className="right ui button" type="button" disabled={value >= maxValue} onClick={this.IncrementItem}>
            +
          </button>
        </Border>
      </div>
    );
  }
}

IncrementInput.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  showBorder: PropTypes.bool.isRequired,
  classname: PropTypes.string.isRequired,
};

export default IncrementInput;
