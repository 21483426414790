import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

import Risk from './Risk';

function MPNPlotMobile({ results, layout, style, annotations }) {
  const newLayout = layout;
  newLayout.legend = {
    x: 0,
    y: -1.4,
    yanchor: 'top',
  };
  return (
    <div>
      <Plot
        data={results}
        layout={newLayout}
        style={style}
        useResizeHandler
      />
      <Risk risks={annotations} />
    </div>
  );
}

MPNPlotMobile.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layout: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  annotations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MPNPlotMobile;
