import axios from 'axios';

import { getApiUrl } from '../utils/Helpers';

import {
  connectError,
  s3Url,
  s3Bucket,
  s3File,
} from '../constants/Config';

import {
  CREATE_ERROR_MESSAGE,
  ADD_MULTIRFX5,
  RESET_MULTIRFX5,
  ADD_MULTIRFX5_COHORT,
  RESET_MULTIRFX5_COHORT,
  ADD_REST_VERSION,
  ADD_COXHD_VERSION,
  ADD_MG14_VERSION,
  CREATE_PATIENT_INPUT,
  UPDATE_PATIENT_INPUT,
  RESET_PATIENT_INPUT,
  UPDATE_COHORT_INPUT,
  RESET_COHORT_INPUT,
  UPDATE_VALIDATION,
  RESET_VALIDATION,
  UPDATE_COHORT_VALIDATION,
  RESET_COHORT_VALIDATION,
  UPDATE_DIPSS,
  RESET_DIPSS,
  UPDATE_DIPSS_VALIDATION,
  RESET_DIPSS_VALIDATION,
  CREATE_GENOMICS_INPUT,
  UPDATE_GENOMICS_INPUT,
  ADD_TGSGENES,
  ADD_MPNINPUT,
  SET_LOADING,
  UPDATE_GENE_PANEL,
  TOGGLE_GENE_PANEL,
  RESET_GENE_PANEL,
  UPDATE_GENES_TABLE,
  RESET_GENES_TABLE,
  ADD_USER_MESSAGE,
} from './types';

export function receiveErrorMessage(value) {
  return {
    type: CREATE_ERROR_MESSAGE,
    value,
  };
}

export function setLoading(value) {
  return {
    type: SET_LOADING,
    value,
  };
}

export function resetMultiRFX5() {
  return {
    type: RESET_MULTIRFX5,
  };
}

export function receiveMultiRFX5(payload) {
  return {
    type: ADD_MULTIRFX5,
    payload,
  };
}

export function resetMultiRFX5Cohort(payload) {
  return {
    type: RESET_MULTIRFX5_COHORT,
    payload,
  };
}

export function receiveMultiRFX5Cohort(payload) {
  return {
    type: ADD_MULTIRFX5_COHORT,
    payload,
  };
}

export function fetchMultiRFX5New(data) {
  const apiUrl = getApiUrl();
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  // console.log('***data=', data);

  // Need to convert CALR1 and CALR2 to CALR 1 or 2

  // HACK need to fix this
  /* eslint-disable no-param-reassign */
  if (data.CALR1 === '1') {
    data.CALR = '1';
  } else if (data.CALR2 === '1') {
    data.CALR = '2';
  }
  /* eslint-enable no-param-reassign */

  return (dispatch) => {
    dispatch(setLoading(true));
    axios.post(`${apiUrl}/MPN/predict`, { input: data }, axiosConfig).then((response) => {
      dispatch(receiveMultiRFX5(response.data));
      dispatch(setLoading(false));
      // Put this here instead of in ComponentDidUpdate in PredictBloodResults to avoid triggering when update DIPSS
      window.scrollBy({ top: window.innerHeight, behavior: 'smooth' });
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function fetchMultiRFX5Exist(data) {
  const apiUrl = getApiUrl();
  // console.log('***Exist data=', data.patientID);

  return (dispatch) => {
    dispatch(setLoading(true));
    axios.get(`${apiUrl}/MPN/cohort?input=${data.patientID}`).then((response) => {
      // console.log('Exist', response.data);
      dispatch(receiveMultiRFX5Cohort(response.data));
      dispatch(setLoading(false));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function receiveRestVersion(payload) {
  return {
    type: ADD_REST_VERSION,
    payload,
  };
}

export function fetchRestVersion() {
  const apiUrl = getApiUrl();

  return (dispatch) => {
    axios.get(`${apiUrl}/version`).then((response) => {
      dispatch(receiveRestVersion(response.data));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function receivemg14Version(payload) {
  return {
    type: ADD_MG14_VERSION,
    payload,
  };
}

export function fetchmg14Version() {
  const apiUrl = getApiUrl();

  return (dispatch) => {
    axios.get(`${apiUrl}/mg14Version`).then((response) => {
      const version = response.data[0].join('.');
      dispatch(receivemg14Version(version));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function receiveCoxHDVersion(payload) {
  return {
    type: ADD_COXHD_VERSION,
    payload,
  };
}

export function fetchCoxHDVersion() {
  const apiUrl = getApiUrl();

  return (dispatch) => {
    axios.get(`${apiUrl}/coxhdVersion`).then((response) => {
      const version = response.data[0].join('.');
      dispatch(receiveCoxHDVersion(version));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

/*
export function receiveTGSgenesInfo(payload) {
  return {
    type: ADD_TGSGENESINFO,
    payload,
  };
}

export function fetchTGSgenesInfo(data) {
  const apiUrl = getApiUrl();
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  console.log('***data=', data);
  //var jsonData = JSON.stringify(data);
 // console.log(jsonData);

  return (dispatch) => {
    axios.get(`${apiUrl}/TGSgenesInfo?input=${data}`, axiosConfig).then((response) => {
      dispatch(receiveTGSgenesInfo(response.data));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}
*/

export function receiveTGSgenes(payload) {
  return {
    type: ADD_TGSGENES,
    payload,
  };
}

export function fetchTGSgenes(data) {
  const apiUrl = getApiUrl();
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };
  // console.log('***data=', data);
  const jsonData = JSON.stringify(data);
  // console.log(jsonData);

  return (dispatch) => {
    axios.get(`${apiUrl}/MPN/mutations?input=${jsonData}`, axiosConfig).then((response) => {
      dispatch(receiveTGSgenes(response.data));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function receiveMPNinput(payload) {
  return {
    type: ADD_MPNINPUT,
    payload,
  };
}

export function fetchMPNinputId(data) {
  const apiUrl = getApiUrl();

  return (dispatch) => {
    axios.get(`${apiUrl}/MPNinput/id?input=${data}`).then((response) => {
      dispatch(receiveMPNinput(response.data[0]));
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function createPatientInput(input) {
  return {
    type: CREATE_PATIENT_INPUT,
    input,
  };
}

export function updatePatientInput(name, value) {
  return {
    type: UPDATE_PATIENT_INPUT,
    name,
    value,
  };
}

export function resetPatientInput() {
  return {
    type: RESET_PATIENT_INPUT,
  };
}

export function updateValidation(name, value) {
  return {
    type: UPDATE_VALIDATION,
    name,
    value,
  };
}

export function resetValidation() {
  return {
    type: RESET_VALIDATION,
  };
}

export function updateCohortInput(name, value) {
  return {
    type: UPDATE_COHORT_INPUT,
    name,
    value,
  };
}

export function resetCohortInput(name, value) {
  return {
    type: RESET_COHORT_INPUT,
    name,
    value,
  };
}

export function updateCohortValidation(name, value) {
  return {
    type: UPDATE_COHORT_VALIDATION,
    name,
    value,
  };
}

export function resetCohortValidation() {
  return {
    type: RESET_COHORT_VALIDATION,
  };
}

export function updateDIPSS(name, value) {
  return {
    type: UPDATE_DIPSS,
    name,
    value,
  };
}

export function resetDIPSS(name, value) {
  return {
    type: RESET_DIPSS,
    name,
    value,
  };
}

export function updateDIPSSValidation(name, value) {
  return {
    type: UPDATE_DIPSS_VALIDATION,
    name,
    value,
  };
}

export function resetDIPSSValidation() {
  return {
    type: RESET_DIPSS_VALIDATION,
  };
}

export function updateGenePanel(name, value, key) {
  return {
    type: UPDATE_GENE_PANEL,
    name,
    value,
    key,
  };
}

export function toggleGenePanel(name, key) {
  return {
    type: TOGGLE_GENE_PANEL,
    name,
    key,
  };
}

export function resetGenePanel() {
  return {
    type: RESET_GENE_PANEL,
  };
}

export function resetGenesTable() {
  return {
    type: RESET_GENES_TABLE,
  };
}

export function updateGenesTable(name, value) {
  return {
    type: UPDATE_GENES_TABLE,
    name,
    value,
  };
}

// HACK currently not used
export function createGenomicsInput() {
  return {
    type: CREATE_GENOMICS_INPUT,
  };
}

// HACK currently not used
export function updateGenomicsInput(name, value) {
  return {
    type: UPDATE_GENOMICS_INPUT,
    name,
    value,
  };
}

/*
 * Retrieve template data and save as file
 */
export function fetchTemplate(filename) {
  const apiUrl = getApiUrl();
  return (dispatch) => {
    axios.get(`${apiUrl}/MultiRFX5/template`).then((response) => {
      // Download the template as a file
      // console.log('data=', response.data);
      const thisData = response.data;
      // console.log('array=', thisData[0]);
      // convert data into 2 rows
      const headerList = [];
      const valueList = [];

      /*
      for (const [key, value] of Object.entries(thisData[0][0])) {
        headerList.push(`"${key}"`);
        valueList.push(value);
      }
      */
      Object.keys(thisData[0][0]).forEach((key) => {
        headerList.push(`"${key}"`);
        valueList.push(thisData[0][0][key].value);
      });

      const data = `${headerList.toString()}\n${valueList.toString()}\n`;
      // console.log('data', data);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).catch((response) => {
      dispatch(receiveErrorMessage(`${connectError}: (${response})`));
    });
  };
}

export function receiveUserMessage(payload) {
  return {
    type: ADD_USER_MESSAGE,
    payload,
  };
}

export function fetchUserMessage() {
  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  };

  return (dispatch) => {
    axios.get(`${s3Url}/${s3Bucket}/${s3File}`, axiosConfig).then((response) => {
      dispatch(receiveUserMessage(response.data));
    }).catch((response) => {
      if (response.response && response.response.status === 403) {
        // no file found, do nothing
        dispatch(receiveUserMessage({}));
      } else {
        dispatch(receiveErrorMessage(`${connectError}: (${response})`));
      }
    });
  };
}
