import React from 'react';
import { Checkbox, Grid } from 'semantic-ui-react';

function GridCheckbox(props) {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Grid.Column>
      <Checkbox {...props} />
    </Grid.Column>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default GridCheckbox;
