import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from './store/index';

import 'semantic-ui-css/semantic.min.css';

import App from './containers/App';
// import reportWebVitals from './reportWebVitals';

import {
  mediaStyle,
  MediaContextProvider,
} from './components/Media';

ReactDOM.render(
  <>
    <style>{mediaStyle}</style>
    <MediaContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </MediaContextProvider>
  </>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
