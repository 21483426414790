import React, { useLayoutEffect } from 'react';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { MPNPublication } from '../../constants/Config';
import WhoBuiltPredictBlood from './WhoBuiltPredictBlood';
import '../../css/styles.css';

function OverviewPage() {
  // Scroll to top of page when linking
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Container className="topbotmargin">
      <h1 id="overview">
        Overview
      </h1>
      <p className="ptext">
        A type of blood cancer (called myeloproliferative neoplasms or MPNs), has extremely varied outcomes.
        Some patients live with their condition for many years, but in other patients, the disease
        can progress to life threatening conditions such as leukaemia or to myelofibrosis.
        <br /><br />
        There are a number of blood cancers that are labelled as myeloproliferative neoplasms,
        including polycythaemia vera (PV), essential thrombocythemia (ET), and myelofibrosis (MF),
        as well as several other rarer conditions.
        <br /><br />
        Many of these conditions are caused by the person having a rare variation in one of three genes:
        JAK2, MPL and CALR. Doing a blood test to look at these three genes and see whether they contain
        one of the variations that can cause a blood cancer is an important part of making a diagnosis.
        <br /><br />
        It is not just these three genes that are involved in causing blood cancers.
        About half of patients also acquire rare variations in other genes. These additional gene
        variations can give us more information about how their disease may develop.
        Availability of these additional genetic tests is increasing, and the results can be
        helpful to individuals and their healthcare professionals.
        <br /><br />
        Predict Blood combines these results with some other information about the individual and
        generates estimates of how the disease may progress and the individual&apos;s overall survival,
        based on knowledge of similar patients with those characteristics in the past.
      </p>
      <h2 id="whoisitfor">
        Who is it for?
      </h2>
      <p className="ptext">
        Predict Blood is relevant for individuals who have been diagnosed by a haematologist with a type of
        blood cancer (myeloproliferative neoplasm), such as polycythaemia vera, essential thrombocythemia,
        myelofibrosis or a related condition.
        The tool requires information about the individual, blood test results from the time of diagnosis, and
        results of genetic tests that can identify the genetic variations that might be affecting the disease. The tool
        should only be used by or in conjunction with a suitably qualified health professional.
        <br /><br />
        If all the information that the tool is asking for is not available, then the tool can still be used, as long
        as some information, such as the type of diagnosis and age at diagnosis, are available. However, if there are
        gaps in the information it may affect the validity of the estimates that the tool provides.
        <br /><br />
        The model behind the tool is based on data from patients for whom the researchers had high quality
        clinical information and test results at the point of diagnosis. The tool uses this information to generate
        estimates of how the disease may progress and the patient&apos;s overall survival, based on similar
        characteristics of patients in the past.
      </p>
      <p className="ptext">
        Predict Blood has not been prospectively validated - that means that we have not used it to estimate what
        might happen to a group of current patients and then waited many years to see how accurate its estimates were.
        Instead we have used it to estimate what might happen to a group of patients in the past and then looked at how
        accurate its estimates were for those patients, given that we could look up their outcomes.
        The tool&apos;s estimates
        were accurate in approximately 80% of cases. Further information regarding the accuracy of the model are
        provided in the <a href={MPNPublication} target="_blank" rel="noreferrer noopener">publication</a>.
      </p>
      <h2 id="howpredictworks">
        How Predict Blood works?
      </h2>
      <p className="ptext">
        The estimates generated by Predict Blood are based on a group of 2035 patients with myeloproliferative
        neoplasms (MPNs) who were part of a research study that investigated how variations in the genetic code
        of the MPNs affected how their disease progressed. These patients were followed up over a period of many
        years in order to see what happened to them and this information allowed us to construct a model:
        a way of estimating what might happen to a new patient given what happened to these past patients.
        <br /><br />
        Predict Blood takes in information about the patient that has been shown to affect their disease. These are:
        their age at diagnosis, their gender, blood test results, whether or not they have an enlarged spleen
        (splenomegaly), whether or not they have had a blood clot (thrombosis) and the information
        from genetic tests. It then generates estimates of how likely different kinds of progression of the
        patient&apos;s disease might be, based on what happened to similar patients in the original research study.
        <br /><br />
        Predict Blood has been tested on other groups of patients to check the estimates that it generated were
        accurate. Whilst Predict Blood produces good estimates, it cannot say exactly what will happen to any
        individual - it is not making a prediction. It provides the average survival rate for people who had
        similar cancers.
        <br /><br />
        The <Link to="/about/technical">Technical</Link> section has more information on how the model was
        developed and how it works.
      </p>
      <WhoBuiltPredictBlood />
      <br />
    </Container>
  );
}

export default OverviewPage;
