import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ModalInformation from './ModalInformation';
import '../css/help.css';

function Help({ help, children, width = 8 }) {
  return (
    <Grid>
      <Grid.Column width={width}>
        <Grid columns={2}>
          <Grid.Column width={1}>
            <ModalInformation header={help.Header} content={help.Content} />
          </Grid.Column>
          <Grid.Column width={15} verticalAlign="middle">
            {children}
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
}

Help.propTypes = {
  children: PropTypes.node.isRequired,
  help: PropTypes.shape({
    Header: PropTypes.string,
    Content: PropTypes.element,
  }).isRequired,
  width: PropTypes.number,
};

Help.defaultProps = {
  width: 8,
};

export default Help;
