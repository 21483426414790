import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DIPSSForm from '../components/DIPSSForm';
import { DIPSSPropTypes, DIPSSValidationPropTypes } from '../utils/PropTypeValues';

import {
  updateDIPSS as actionUpdateDIPSS,
  updateDIPSSValidation as actionUpdateDIPSSValidation,
} from '../actions';

import {
  dipssValues,
} from '../constants/Config';

const mapDispatchToProps = (dispatch) => ({
  updateDIPSS: (name, value) => dispatch(actionUpdateDIPSS(name, value)),
  updateDIPSSValidation: (name, value) => dispatch(actionUpdateDIPSSValidation(name, value)),
});

const mapStateToProps = (state) => ({
  DIPSS: state.DIPSS,
  DIPSSValidation: state.DIPSSValidation,
  patientInput: state.patientInput,
  validation: state.validation,
});

export function getDIPSSValue(name, value) {
  const { conversion, operand, operator, values } = dipssValues[name];
  let newValue;
  if (operator === 'le') {
    newValue = (value / conversion) <= operand ? values[0] : values[1];
  } else if (operator === 'ge') {
    newValue = (value / conversion) >= operand ? values[0] : values[1];
  }
  return newValue;
}

export class ConnectedDIPSSPage extends React.Component {
  handleChange = (e, { name, value }) => {
    const { updateDIPSS, DIPSS, updateDIPSSValidation, patientInput, validation } = this.props;

    let isValid = true;
    let newTotal = value;
    let validForm = true;
    Object.keys(dipssValues).forEach((key) => {
      if (key !== name && key !== 'total') {
        newTotal += DIPSS[key];
      }
    });

    const toolName = name.substring(1);
    if (toolName in patientInput && !(Number.isNaN(patientInput[toolName]))) {
      const newValue = getDIPSSValue(name, patientInput[toolName]);
      if (newValue !== value) {
        isValid = false;
        validForm = false;
      }
      if (!validation[toolName]) {
        isValid = false;
        validForm = false;
      }
    }
    // Should only update total if all is valid

    // Store in redux
    updateDIPSS(name, value);
    if (validForm) {
      updateDIPSS('total', newTotal);
    }

    // Check validation and store
    updateDIPSSValidation(name, isValid);
  };

  render() {
    const { DIPSS, DIPSSValidation, patientInput } = this.props;
    return (
      <DIPSSForm DIPSS={DIPSS} DIPSSValidation={DIPSSValidation} input={patientInput} onChange={this.handleChange} />
    );
  }
}

ConnectedDIPSSPage.propTypes = {
  updateDIPSS: PropTypes.func.isRequired,
  DIPSS: DIPSSPropTypes.isRequired,
  DIPSSValidation: DIPSSValidationPropTypes.isRequired,
  updateDIPSSValidation: PropTypes.func.isRequired,
  patientInput: PropTypes.shape({}).isRequired,
  validation: PropTypes.shape({}).isRequired,
};

const DIPSSPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDIPSSPage);

export default DIPSSPage;
