import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { DIPSSPropTypes, DIPSSValidationPropTypes } from '../utils/PropTypeValues';
import {
  DIPSSScore,
  onlineDIPSSTool,
} from '../constants/Config';

import styles from '../css/styles.module.css';

function DIPSSResults({ DIPSS, DIPSSValidation, show }) {
  const { total } = DIPSS;
  let risk = '';
  let median = '';

  // Only show results if we have a total
  if (Number.isNaN(total)) {
    return (<div />);
  }

  // Check if any part of DIPSS is invalid
  let isValid = true;
  Object.keys(DIPSSValidation).forEach((key) => {
    if (key !== 'total' && !(DIPSSValidation[key])) {
      isValid = false;
    }
  });

  if (!isValid) {
    return (<div />);
  }

  DIPSSScore.forEach((score) => {
    if (total >= score.min && total <= score.max) {
      risk = score.risk;
      median = score.median;
    }
  });

  // Show results if 'show' is true else only show on media
  return (
    <div className={`${show ? '' : styles.noshow}`}>
      <Segment>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column>
              <Header as="h4" name="dipssScore">DIPSS Score</Header>
              <p>{ total } points</p>
            </Grid.Column>
            <Grid.Column>
              <h4>Risk group</h4>
              <p>{ risk } risk</p>
            </Grid.Column>
            <Grid.Column>
              <h4>Median survial</h4>
              <p>{ median }</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <a
        href={onlineDIPSSTool}
        target="_blank"
        rel="noreferrer noopener"
      >Online DIPSS tool
      </a>
    </div>
  );
}

DIPSSResults.propTypes = {
  show: PropTypes.bool.isRequired,
  DIPSS: DIPSSPropTypes.isRequired,
  DIPSSValidation: DIPSSValidationPropTypes.isRequired,
};

export default DIPSSResults;
