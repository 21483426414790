import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function GenePanel({ checkboxes, title, numCol = 6, elem = <div /> }) {
  return (
    <div>
      <h3>{title}</h3>
      { elem }
      <Segment>
        <Grid stackable columns={numCol}>
          { checkboxes }
        </Grid>
      </Segment>
    </div>
  );
}

GenePanel.propTypes = {
  checkboxes: PropTypes.arrayOf(PropTypes.element).isRequired,
  title: PropTypes.string.isRequired,
  numCol: PropTypes.number,
  elem: PropTypes.element,
};

GenePanel.defaultProps = {
  numCol: 6,
  elem: <div />,
};

export default GenePanel;
