import React from 'react';
import { Header, Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { parseRisk } from '../utils/Helpers';
import { outcomes, yearText } from '../constants/Config';

const styleTable = {
  width: 'min(50vw, 400px)',
  height: 'calc(min(50vw, 400px) * 0.9',
  marginTop: '20px',
};

const styleCell = {
  // backgroundColor: 'blue',
  // color: 'white',
  textAlign: 'center',
  fontSize: '15px',
};

// Row title
function rowTitle(year) {
  return (
    <Table.Cell key={year}>
      <Header style={styleCell}>
        <Header.Content>
          {year} Years
        </Header.Content>
      </Header>
    </Table.Cell>
  );
}

function columnTitle(risk) {
  // Only using second returned value
  const riskFactors = parseRisk(risk);
  const { title } = outcomes[riskFactors.outcome];

  return (
    <Table.HeaderCell key={title}>
      <Header style={styleCell}>
        <Header.Content>
          {title}
        </Header.Content>
      </Header>
    </Table.HeaderCell>
  );
}

// Create individual cell in row
function generateCell(risk) {
  const riskFactors = parseRisk(risk);
  const { percentage, outcome, year } = riskFactors;

  const name = `${outcome}${year}`;
  return (
    <Table.Cell id={name} className={name} key={name} style={styleCell}>{percentage}%</Table.Cell>
  );
}

// Numerical sort
function compareNumbers(a, b) {
  return a - b;
}

function ResultTable({ multiRFX5 }) {
  if (_.isEmpty(multiRFX5)) {
    return (<div />);
  }

  const titles = [];
  const rows = [];

  // Populate column headings
  titles.push(<Table.HeaderCell key="spacer" />);
  const firstYearText = Object.values(yearText)[0];
  multiRFX5[firstYearText].forEach((risk) => {
    const title = columnTitle(risk);
    titles.push(title);
  });

  // Populate rows
  Object.keys(yearText).sort(compareNumbers).forEach((year) => {
    const cells = [];
    // Row heading
    cells.push(rowTitle(year));
    // Row cells
    multiRFX5[yearText[year]].forEach((risk) => {
      cells.push(generateCell(risk));
    });
    rows.push(<Table.Row key={year}>{cells}</Table.Row>);
  });

  return (
    <div>
      <Table basic="very" celled collapsing style={styleTable}>
        <Table.Header>
          <Table.Row>
            {titles}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows}
        </Table.Body>
      </Table>
    </div>
  );
}

ResultTable.propTypes = {
  multiRFX5: PropTypes.shape({
    plot: PropTypes.arrayOf(PropTypes.shape({})),
    line: PropTypes.arrayOf(PropTypes.shape({})),
    fiveyr: PropTypes.arrayOf(PropTypes.string),
    tenyr: PropTypes.arrayOf(PropTypes.string),
    twentyyr: PropTypes.arrayOf(PropTypes.string),
    MEFS: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ResultTable;
