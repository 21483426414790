import React from 'react';
import { Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function ResponsiveMenuDesktop({ leftItems }) {
  return (
    <Menu>
      {leftItems}
    </Menu>
  );
}

ResponsiveMenuDesktop.propTypes = {
  leftItems: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ResponsiveMenuDesktop;
