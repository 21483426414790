import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import GridLabeledInput from './GridLabeledInput';
import ModalInformation from './ModalInformation';
import ButtonRow from './ButtonRow';
import {
  initialDiagnosisElement,
} from '../constants/Config';

import ModalData from '../constants/HelpText';

function InitialDiagnosis({ patientInput, onChange, validation }) {
  const labelWidth = 4;
  const componentWidth = 8;

  const { name, label, buttons } = initialDiagnosisElement;
  const showBorder = !validation[name];

  // Find which button is currently active
  let currValue = '';
  buttons.forEach((button) => {
    if (patientInput[name] === button.value) {
      currValue = button.label;
    }
  });

  return (
    <GridLabeledInput
      help={(
        <ModalInformation
          header={ModalData.initialDiagnosis.Header}
          content={ModalData.initialDiagnosis.Content}
        />
      )}
      label={label}
      labelWidth={labelWidth}
      componentWidth={componentWidth}
      component={(
        <Form.Field
          placeholder={name}
          name={name}
          value={currValue}
          onChange={onChange}
          control={ButtonRow}
          showBorder={showBorder}
          setValue={(e, value) => onChange(e,
            { name, value: buttons.filter((item) => item.label === value)[0].value })}
          buttonList={buttons.map((item) => item.label)}
          key={name}
        />
      )}
    />
  );
}

InitialDiagnosis.propTypes = {
  patientInput: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  validation: PropTypes.shape({}).isRequired,
};

export default InitialDiagnosis;
