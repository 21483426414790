import React, { useLayoutEffect } from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../../css/styles.css';

import DisclaimerNotice from './DisclaimerNotice';

function DisclaimerPage({ banner, headerBand }) {
  // Scroll to top of page when linking
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1>Terms of Use</h1>
        <DisclaimerNotice />
        <br />
        <p className="ptext">
          You <b>MUST</b> read the information below before using the tool
        </p>
        <p className="ptext">
          Predict Blood aims to help estimate MPN disease progression. This tool is for use by or in consultation with
          healthcare professionals. Results provided by this tool are for informational purposes only and are not
          intended as a substitute for professional medical advice and counselling. The tool is not designed to make
          treatment recommendations and treatment decisions should be undertaken in line with the applicable
          medical guidelines.
          <br /> <br />
          This web tool allows for the input of some clinical and genomic information from individual patients with
          MPN to provide personalised estimates on disease progression and patient survival.
          It is based on data and prognostic models from Grinfeld and Nangalia et al. 2018. The original publication
          can be found at Grinfeld et al., N Engl J Med 2018 Oct 11;379(15):1416-30.
        </p>
        <p className="ptext">
          You may download a copy of individual estimates generated by the tool for information purposes. Other than
          this, you shall not copy, reproduce, distribute, transmit, broadcast, display, sell, rent, license, or
          otherwise exploit this tool or web portal or any content within in whole or in part for any other purposes
          without the prior written consent of the Wellcome Sanger Institute.
          <br /> <br />
          The tool is made available free of charge. To the fullest extent permitted by law, neither the authors nor
          Wellcome Sanger Institute accept any liability for errors in the tool or for any use of it.
          <br /> <br />
          Links from this tool to other sites do not imply recommendations or endorsements of any organisations,
          products or services.
          <br /> <br />
          By using this web portal, you confirm that you agree to the terms set out above.
        </p>
      </Container>
      { banner }
    </div>
  );
}

DisclaimerPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default DisclaimerPage;
