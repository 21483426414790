import React from 'react';
import { Grid, Container } from 'semantic-ui-react';
import '../../css/introbanner.css';

import WhatDoesPredictDo from './WhatDoesPredictDo';
import WhoIsPredictFor from './WhoIsPredictFor';
import FindOutMore from './FindOutMore';

function IntroBanner() {
  return (
    <div>
      <Container textAlign="center" className="introbanner">
        <Grid columns={3} stackable>
          <Grid.Column>
            <WhatDoesPredictDo />
          </Grid.Column>
          <Grid.Column>
            <WhoIsPredictFor />
          </Grid.Column>
          <Grid.Column>
            <FindOutMore />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

export default IntroBanner;
