import React from 'react';
import { Grid, Segment, Divider } from 'semantic-ui-react';
import '../../css/infobanner.css';

import UsePredict from './UsePredict';
import Support from './Support';

function InfoBanner() {
  return (
    <div>
      <Divider hidden />
      <Segment textAlign="center" className="infobanner">
        <Grid columns={2} stackable>
          <Grid.Column>
            <UsePredict />
          </Grid.Column>
          <Grid.Column>
            <Support />
          </Grid.Column>
        </Grid>
      </Segment>
    </div>
  );
}

export default InfoBanner;
