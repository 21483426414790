import update from 'immutability-helper';

import {
  ADD_MULTIRFX5_COHORT,
  RESET_MULTIRFX5_COHORT,
} from '../actions/types';

function multiRFX5Cohort(state = {}, action = {}) {
  switch (action.type) {
    case ADD_MULTIRFX5_COHORT:
    // console.log('PAYLOAD');
    // console.log('Reducer multiRFX5Cohort', action.payload);
      return action.payload;
    case RESET_MULTIRFX5_COHORT:
      return update(state, {
        $set: {},
      });
    default:
      return state;
  }
}

export default multiRFX5Cohort;
