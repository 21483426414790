import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';

import PredictBloodForm from './PredictBloodForm';
import PredictBloodResults from './PredictBloodResults';
import { DIPSSPropTypes, DIPSSValidationPropTypes } from '../utils/PropTypeValues';

const mapStateToProps = (state) => ({
  multiRFX5: state.multiRFX5,
  DIPSS: state.DIPSS,
  DIPSSValidation: state.DIPSSValidation,
  genesTable: state.genesTable,
  patientInput: state.patientInput,
});

export function ConnectedPredictBloodPage({ multiRFX5, DIPSS, DIPSSValidation, patientInput, banner }) {
  let patientID = '';
  if (patientInput && patientInput.patientID) {
    patientID = patientInput.patientID;
  }
  return (
    <div>
      { banner }
      <Container>
        <h1>
          Predict Blood tool
        </h1>
        <PredictBloodForm />
        <PredictBloodResults
          multiRFX5={multiRFX5}
          DIPSS={DIPSS}
          DIPSSValidation={DIPSSValidation}
          patientID={patientID}
        />
      </Container>
    </div>
  );
}

ConnectedPredictBloodPage.propTypes = {
  multiRFX5: PropTypes.shape({
    patientData: PropTypes.shape({
      age: PropTypes.arrayOf(PropTypes.number),
      hb: PropTypes.arrayOf(PropTypes.number),
      mutations: PropTypes.arrayOf(PropTypes.string),
      patientID: PropTypes.arrayOf(PropTypes.string),
      platelet: PropTypes.arrayOf(PropTypes.number),
      sex: PropTypes.arrayOf(PropTypes.number),
      wcc: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  DIPSS: DIPSSPropTypes,
  DIPSSValidation: DIPSSValidationPropTypes,
  patientInput: PropTypes.shape({ patientID: PropTypes.string }).isRequired,
  banner: PropTypes.element.isRequired,
};

ConnectedPredictBloodPage.defaultProps = {
  DIPSS: {},
  DIPSSValidation: {},
};

const PredictBloodPage = connect(mapStateToProps)(ConnectedPredictBloodPage);

export default PredictBloodPage;
