import React from 'react';

class DefaultPage extends React.Component {
  async componentDidMount() {
    document.title = '404 - Not Found';
  }

  render() {
    return (
      <div className="ui container">
        <h4>404 - Page Not Found</h4>
      </div>
    );
  }
}

export default DefaultPage;
