import React from 'react';
import PropTypes from 'prop-types';

export function renderError({ error, touched }) {
  if (touched && error) {
    return (
      <div className="ui compact error mini message">
        <div className="header">{error}</div>
      </div>
    );
  }
  // return empty div if no errors
  return (<div />);
}

export function renderBatchError(error) {
  if (error) {
    return (
      <div className="ui compact error mini message">
        <div className="header">{error}</div>
      </div>
    );
  }
  // return empty div if no errors
  return (<div />);
}

renderError.propTypes = {
  error: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
};
