import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Segment, Header } from 'semantic-ui-react';

import NewPatientForm from '../components/NewPatientForm';
import DIPSSPage, { getDIPSSValue } from './DIPSSPage';
import ResetHelpInfoText from '../components/ResetHelpInfoText';
import { DIPSSPropTypes } from '../utils/PropTypeValues';

import {
  fieldValidation,
  isValidForm,
} from '../utils/Helpers';

import {
  updatePatientInput as actionUpdatePatientInput,
  resetPatientInput as actionResetPatientInput,
  updateValidation as actionUpdateValidation,
  resetValidation as actionResetValidation,
  fetchMultiRFX5New as actionFetchMultiRFX5New,
  resetMultiRFX5 as actionResetMultiRFX5,
  updateDIPSS as actionUpdateDIPSS,
  updateDIPSSValidation as actionUpdateDIPSSValidation,
  resetDIPSS as actionResetDIPSS,
  resetDIPSSValidation as actionResetDIPSSValidation,
  resetGenesTable as actionResetGenesTable,
} from '../actions';

import {
  requiredPatientInputFields,
  formValues,
  diagnoses,
  dipssValues,
} from '../constants/Config';

import DisclaimerModal from '../components/DisclaimerModal';

const mapDispatchToProps = (dispatch) => ({
  updatePatientInput: (name, value) => dispatch(actionUpdatePatientInput(name, value)),
  resetPatientInput: () => dispatch(actionResetPatientInput()),
  updateValidation: (name, value) => dispatch(actionUpdateValidation(name, value)),
  resetValidation: () => dispatch(actionResetValidation()),
  fetchMultiRFX5New: (data) => dispatch(actionFetchMultiRFX5New(data)),
  resetMultiRFX5: () => dispatch(actionResetMultiRFX5()),
  updateDIPSS: (name, value) => dispatch(actionUpdateDIPSS(name, value)),
  updateDIPSSValidation: (name, value) => dispatch(actionUpdateDIPSSValidation(name, value)),
  resetDIPSS: (name, value) => dispatch(actionResetDIPSS(name, value)),
  resetDIPSSValidation: (name, value) => dispatch(actionResetDIPSSValidation(name, value)),
  resetGenesTable: () => dispatch(actionResetGenesTable()),
});

const mapStateToProps = (state) => ({
  patientInput: state.patientInput,
  loading: state.loading,
  validation: state.validation,
  DIPSS: state.DIPSS,
  genePanel: state.genePanel,
});

// Only display DIPSS when initital diagnosis is MF
function displayDIPSS(patientInput) {
  if (patientInput.initialDiagnosis === 'MF') {
    return (
      <Segment>
        <Header as="h1" name="dipssHeader">DIPSS</Header>
        <DIPSSPage input={patientInput} />
      </Segment>
    );
  }
  return (<div />);
}

export class ConnectedPredictBloodForm extends React.Component {
  constructor(props) {
    super(props);
    this.disclaimerModalRef = React.createRef();
  }

  handleModal = () => {
    // Call DisclaimerModal onSubmit function
    this.disclaimerModalRef.current.onSubmit();
  };

  handleSubmit = () => {
    const { patientInput, fetchMultiRFX5New } = this.props;
    // console.log('SUBMITTING');

    // console.log('input=', patientInput);
    // Remove patientID from input
    const newPatientInput = {};
    Object.keys(patientInput).forEach((elem) => {
      if (elem !== 'patientID') {
        newPatientInput[elem] = patientInput[elem];
      }
    });

    fetchMultiRFX5New(newPatientInput);
  };

  handleGeneChange = ({ name, value }) => {
    const { updatePatientInput } = this.props;
    // Store in redux
    updatePatientInput(name, value);
  };

  handleChange = (e, { name, value }) => {
    const { updatePatientInput, updateValidation, updateDIPSS, updateDIPSSValidation, DIPSS } = this.props;
    // console.log('Updating input New=', e, name, value);
    // console.log('CHANGE name=', name, value);

    // This will be set to the type but we want to toggle all the values
    if (name === 'initialDiagnosis') {
      diagnoses.forEach((diagnosis) => {
        if (diagnosis === value) {
          updatePatientInput(diagnosis, 1);
        } else {
          updatePatientInput(diagnosis, 0);
        }
      });
    }

    // Update validation state
    // true: valid value
    // false: invalid value
    const elem = formValues[name];
    updateValidation(name, fieldValidation(elem, value));

    // Store in redux
    updatePatientInput(name, value);

    // Update DIPSS
    let newTotal = 0;
    let dipssIsValid = true;
    const dipssName = `d${name}`;
    Object.keys(dipssValues).forEach((key) => {
      if (key === dipssName) {
        const newValue = getDIPSSValue(dipssName, value);
        newTotal += newValue;
        updateDIPSS(dipssName, newValue);
        if (fieldValidation(elem, value)) {
          updateDIPSSValidation(dipssName, true);
        } else {
          updateDIPSSValidation(dipssName, false);
          dipssIsValid = false;
        }
      } else if (key !== 'total') {
        newTotal += DIPSS[key];
      }
    });

    if (dipssIsValid) {
      updateDIPSS('total', newTotal);
    } else {
      updateDIPSS('total', NaN);
    }
  };

  reset = (e) => {
    const {
      resetPatientInput,
      resetValidation,
      resetMultiRFX5,
      resetDIPSS,
      resetDIPSSValidation,
      resetGenesTable } = this.props;
    resetPatientInput();
    resetValidation();
    resetDIPSS();
    resetDIPSSValidation();
    resetGenesTable();

    // Remove any current plots
    resetMultiRFX5();
    e.preventDefault();
  };

  render() {
    const { loading, patientInput, validation, genePanel } = this.props;

    // Check status of input data
    // disabled is true if form is invalid ie false
    const disabled = !isValidForm(requiredPatientInputFields, validation);
    // console.log('Render patientInput', patientInput);
    // console.log('validation', validation);

    const dipssForm = displayDIPSS(patientInput);
    return (
      <Form onSubmit={this.handleModal}>
        <Segment>
          <ResetHelpInfoText onClick={this.reset} />
          <NewPatientForm
            input={patientInput}
            onChange={this.handleChange}
            validation={validation}
            genePanel={genePanel}
            onGeneChange={this.handleGeneChange}
          />
          <Segment basic textAlign="center">
            <Form.Button
              loading={loading}
              disabled={disabled}
              content="Estimate outcome for a patient like this"
              primary
            />
          </Segment>
        </Segment>
        <DisclaimerModal ref={this.disclaimerModalRef} onSave={this.handleSubmit} />
        {dipssForm}
      </Form>
    );
  }
}

ConnectedPredictBloodForm.propTypes = {
  fetchMultiRFX5New: PropTypes.func.isRequired,
  resetMultiRFX5: PropTypes.func.isRequired,
  updatePatientInput: PropTypes.func.isRequired,
  resetPatientInput: PropTypes.func.isRequired,
  updateValidation: PropTypes.func.isRequired,
  resetValidation: PropTypes.func.isRequired,
  updateDIPSS: PropTypes.func.isRequired,
  updateDIPSSValidation: PropTypes.func.isRequired,
  resetDIPSS: PropTypes.func.isRequired,
  resetDIPSSValidation: PropTypes.func.isRequired,
  resetGenesTable: PropTypes.func.isRequired,
  patientInput: PropTypes.shape({}).isRequired,
  validation: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  genePanel: PropTypes.shape({}).isRequired,
  DIPSS: DIPSSPropTypes,
};

ConnectedPredictBloodForm.defaultProps = {
  DIPSS: {},
};

const PredictBloodForm = connect(mapStateToProps, mapDispatchToProps)(ConnectedPredictBloodForm);

export default PredictBloodForm;
