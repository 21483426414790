import React from 'react';
import { Button, Image } from 'semantic-ui-react';
import { HashLink as Link } from 'react-router-hash-link';

import '../../css/styles.css';
import faqIcon from '../../images/faq-icon.png';

function Support() {
  return (
    <div>
      <Image src={faqIcon} />
      <h2 id="support">
        Someone to talk to?
      </h2>
      <p className="ptext">
        If you are fighting cancer, it’s often easier with support. Find further information and links.
      </p>
      <Button size="big" as={Link} to="/about/faq#lookadvice" primary>Support Links</Button>
    </div>
  );
}

export default Support;
