import update from 'immutability-helper';

import {
  CREATE_GENOMICS_INPUT,
  UPDATE_GENOMICS_INPUT,
} from '../actions/types';

import {
  geneList,
} from '../constants/Config';

function genomicsInput(
  state = {
    Gene1: geneList[0],
    Gene2: geneList[0] },
  action = {}) {
  switch (action.type) {
    case CREATE_GENOMICS_INPUT:
      return state;
    case UPDATE_GENOMICS_INPUT:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    default:
      return state;
  }
}

export default genomicsInput;
