import React from 'react';
import { Grid, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import '../css/risk.css';

/*
 * Print survival and risk rates
 */
function Risk({ risks }) {
  const riskTextList = [];
  const width = 5;
  // Group risks into rows, given by y coordinate
  const riskRows = {};
  risks.forEach((risk) => {
    if (!risk.text.match('Expected median')) {
      if (risk.y in riskRows) {
        riskRows[risk.y].push(<Grid.Column width={width} key={risk.text}> {risk.text} </Grid.Column>);
      } else {
        riskRows[risk.y] = [<Grid.Column width={width} key={risk.text}> {risk.text} </Grid.Column>];
      }
    }
  });
  let i = 0;
  Object.keys(riskRows).sort().forEach((y) => {
    riskTextList.push(<Grid.Row key={i}>{riskRows[y]}</Grid.Row>);
    i += 1;
  });

  return (
    <Message>
      <Grid divided columns={3}>
        { riskTextList }
      </Grid>
    </Message>
  );
}

Risk.propTypes = {
  risks: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      xref: PropTypes.string,
      yref: PropTypes.string,
      text: PropTypes.string,
      showarrow: PropTypes.bool,
    })).isRequired,
};

export default Risk;
