import React from 'react';
import { Tab } from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MPNPlot from '../components/MPNPlot';
import PatientDescription from '../components/PatientDescription';
import PatientOutcome from '../components/PatientOutcome';
import PrinterWrapper from '../components/PrinterWrapper';
import ReportHeader from '../components/ReportHeader';
import ResultTable from '../components/ResultTable';
import ResultText from '../components/ResultText';
import DIPSSResults from '../components/DIPSSResults';
import {
  DIPSSPropTypes,
  DIPSSValidationPropTypes,
  multiRFX5PropTypes,
} from '../utils/PropTypeValues';

import styles from '../css/styles.module.css';

function PredictBloodResults({ multiRFX5, DIPSS, DIPSSValidation, patientID }) {
  // Check if multiRFX5 is empty
  if (_.isEmpty(multiRFX5)) {
    return (<div />);
  }
  // eslint seems very confused by this. Code example from Tab example in react.semantic.ui
  /* eslint-disable react/no-multi-comp */
  /* eslint-disable react/display-name */
  const panes = [
    {
      menuItem: { content: 'Curves', id: 'curvesTab', key: 'curvesTab' },
      render: () => <MPNPlot multiRFX5={multiRFX5} print={false} />,
    },
    {
      menuItem: { content: 'Table', id: 'tableTab', key: 'tableTab' },
      render: () => <ResultTable multiRFX5={multiRFX5} />,
    },
    {
      menuItem: { content: 'Texts', id: 'textTab', key: 'textTab' },
      render: () => <ResultText multiRFX5={multiRFX5} />,
    },
  ];
  /* eslint-enable react/no-multi-comp */
  /* eslint-enable react/display-name */

  const filename = 'PredictBloodReport';

  /*
   * Always display the table and plot in the report
   */
  return (
    <div>
      <PrinterWrapper docTitle={filename}>
        <ReportHeader />
        <PatientDescription multiRFX5={multiRFX5} patientID={patientID} />
        <PatientOutcome multiRFX5={multiRFX5} />
        <DIPSSResults DIPSS={DIPSS} DIPSSValidation={DIPSSValidation} show={false} />
        <div className={styles.noshow}>
          <ResultTable multiRFX5={multiRFX5} />
          <div className={styles.pagebreak} />
          <MPNPlot multiRFX5={multiRFX5} print />
        </div>
      </PrinterWrapper>
      <Tab menu={{ secondary: true }} panes={panes} />
    </div>
  );
}

PredictBloodResults.propTypes = {
  multiRFX5: multiRFX5PropTypes.isRequired,
  DIPSS: DIPSSPropTypes,
  DIPSSValidation: DIPSSValidationPropTypes,
  patientID: PropTypes.string,
};

PredictBloodResults.defaultProps = {
  DIPSS: {},
  DIPSSValidation: {},
  patientID: '',
};

export default PredictBloodResults;
