import {
  ADD_REST_VERSION,
} from '../actions/types';

function restVersion(state = {}, action = {}) {
  switch (action.type) {
    case ADD_REST_VERSION:
      // console.log('Reducer restVersion', action.payload);
      return action.payload;
    default:
      return state;
  }
}

export default restVersion;
