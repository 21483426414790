import React from 'react';
import { Link } from 'react-router-dom';

function DisclaimerNotice() {
  return (
    <div>
      <p className="ptext">
        In using Predict Blood, you confirm that you have read the information in
        the <Link to="/about" target="_blank" rel="noopener noreferrer">About Predict Blood</Link> section
        and that you agree to the terms set out in
        the <Link to="/legal/disclaimer" target="_blank" rel="noopener noreferrer">Terms of Use</Link> section.
      </p>
    </div>
  );
}

export default DisclaimerNotice;
