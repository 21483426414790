import React from 'react';
import {
  MPNPublication,
  NangaliaURL,
  SangerGenomicsInnovationURL,
  wintoncentreURL,
  casmURL,
  sangerURL,
} from '../../constants/Config';

function WhoBuiltPredictBlood() {
  return (
    <div>
      <h2>
        Who built Predict Blood?
      </h2>
      <p className="ptext">
        The model was built as part of an academic research project. The original publication can be found
        at {' '}
        <a href={MPNPublication} target="_blank" rel="noreferrer noopener">{MPNPublication}</a>. The website
        was built by the  {' '}
        <a
          href={sangerURL}
          target="_blank"
          rel="noreferrer noopener"
        > Wellcome Sanger Institute
        </a> (
        <a href={NangaliaURL} target="_blank" rel="noreferrer noopener">Nangalia Group</a> and
        the <a href={casmURL} target="_blank" rel="noreferrer noopener">Cancer, Ageing and Somatic Mutation</a> IT
        team). The work was supported by the {' '}
        <a
          href={SangerGenomicsInnovationURL}
          target="_blank"
          rel="noreferrer noopener"
        >Sanger Genomics Innovation Team
        </a> together with advice from the {' '}
        <a
          href={wintoncentreURL}
          target="_blank"
          rel="noreferrer noopener"
        >Winton Centre for Risk and Evidence Communication
        </a>.
      </p>
    </div>
  );
}

export default WhoBuiltPredictBlood;
