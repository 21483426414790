import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import WhatIsPredict from '../components/WhatIsPredict';

function HomePage({ banner }) {
  return (
    <div>
      <WhatIsPredict />
      <Divider hidden />
      { banner }
    </div>
  );
}

HomePage.propTypes = {
  banner: PropTypes.element.isRequired,
};

export default HomePage;
