import React from 'react';
import { Grid, Container, Image } from 'semantic-ui-react';
import '../../css/footerbanner.css';
import sangerLogo from '../../images/SAN001-Sanger-Master-Logo-White-S1_AW_rgb.png';
import predictLogo from '../../images/predict_blood_white_testtube.png';

function FooterBanner() {
  return (
    <div>
      <Container textAlign="center" className="footerbanner">
        <Grid columns={2} stackable>
          <Grid.Column>
            <Image src={predictLogo} size="small" style={{ marginTop: '5px' }} />
          </Grid.Column>
          <Grid.Column>
            <Image src={sangerLogo} size="small" style={{ marginTop: '5px' }} />
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

export default FooterBanner;
