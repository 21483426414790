import React from 'react';
import { Segment, Message, Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import GenomicsPlot from './GenomicsPlot';
import '../css/styles.css';

function CohortMutationPage({ banner }) {
  return (
    <div>
      { banner }
      <Container className="topbotmargin">
        <h1>
          Cohort Mutations
        </h1>
        <Message>
          <p className="ptext">
            Select one or two genes to see the frequency of mutation in patients with PV, ET and MF
            in the original cohort.
          </p>
        </Message>
        <Segment>
          <GenomicsPlot />
        </Segment>
      </Container>
    </div>
  );
}

CohortMutationPage.propTypes = {
  banner: PropTypes.element.isRequired,
};

export default CohortMutationPage;
