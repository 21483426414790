import {
  ADD_MG14_VERSION,
} from '../actions/types';

function mg14Version(state = {}, action = {}) {
  switch (action.type) {
    case ADD_MG14_VERSION:
      // console.log('Reducer coxhdVersion', action.payload);
      return action.payload;
    default:
      return state;
  }
}

export default mg14Version;
