import React from 'react';
import { Image } from 'semantic-ui-react';

import '../../css/styles.css';

import patientIcon from '../../images/patient-icon.png';

function WhoIsPredictFor() {
  return (
    <div>
      <Image src={patientIcon} />
      <h2 id="whopredict">
        Who is Predict for?
      </h2>
      <p className="ptext">
        Predict is based on the input of genetic information and should only be used by a suitably qualified health
        professional. <br />
      </p>
    </div>
  );
}

export default WhoIsPredictFor;
