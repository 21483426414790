import React from 'react';
import { connect } from 'react-redux';
import { Segment, Divider, Grid, Image, Container, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import refIcon from '../images/ref.png';
import lotIcon from '../images/lot.png';
import bookIcon from '../images/book.png';
import factoryIcon from '../images/factory.png';
import ukcaIcon from '../images/UKCA_black_fill.png';

import {
  sangerName,
  sangerAddress,
  coxhdURL,
  mg14URL,
  medicalDevices,
  medicalDeviceRegulation } from '../constants/Config';
import '../css/styles.css';

import {
  fetchRestVersion as actionFetchRestVersion,
  fetchCoxHDVersion as actionFetchCoxHDVersion,
  fetchmg14Version as actionFetchmg14Version,
} from '../actions';

const mapDispatchToProps = (dispatch) => ({
  fetchRestVersion: () => dispatch(actionFetchRestVersion()),
  fetchCoxHDVersion: () => dispatch(actionFetchCoxHDVersion()),
  fetchmg14Version: () => dispatch(actionFetchmg14Version()),
});

const mapStateToProps = (state) => ({
  restVersion: state.restVersion,
  coxhdVersion: state.coxhdVersion,
  mg14Version: state.mg14Version,
});

function createRow(image, text) {
  return (
    <Grid.Row>
      <Grid.Column width={2}>
        <Image src={image} width="50px" />
      </Grid.Column>
      <Grid.Column>
        <p className="ptext">
          {text}
        </p>
      </Grid.Column>
    </Grid.Row>
  );
}

function createLink(href, text) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
    >{text}
    </a>
  );
}

function createListItem(item) {
  return (
    <List.Item className="ptext">
      <List.Content>
        <List.Description>{item}</List.Description>
      </List.Content>
    </List.Item>
  );
}

function createListItemIcon(header, item) {
  return (
    <List.Item className="ptext">
      <List.Icon name="right triangle" />
      <List.Content>
        <List.Header key={header}>{header}</List.Header>
        <List.Description>{item}</List.Description>
      </List.Content>
    </List.Item>
  );
}

function getVersionText(version) {
  return (
    <span id="versions">
      Version: {version}
    </span>
  );
}

function getRegulationText() {
  return (
    <span>
      {medicalDeviceRegulation}
    </span>
  );
}

function getMedicalDeviceText() {
  const rows = [];
  const regulationText = getRegulationText();
  rows.push(createListItem(regulationText));
  medicalDevices.forEach((device) => {
    rows.push(createListItemIcon(device.header, device.item));
  });
  return (rows);
}

export function ConnectedLegalPage({
  fetchRestVersion,
  restVersion,
  fetchCoxHDVersion,
  coxhdVersion,
  fetchmg14Version,
  mg14Version,
  banner,
  headerBand }) {
  const version = process.env.REACT_APP_VERSION;
  const link = <Link to="/about">About Predict</Link>;
  const refer = <span>Refer to { link } for instructions</span>;
  fetchRestVersion();
  fetchCoxHDVersion();
  fetchmg14Version();

  // Check versions have been set
  if (_.isEmpty(restVersion) || _.isEmpty(coxhdVersion) || _.isEmpty(mg14Version)) {
    return (<div />);
  }

  const coxhdLink = createLink(coxhdURL, 'CoxHD');
  const mg14Link = createLink(mg14URL, 'mg14');
  const versionText = getVersionText(version, restVersion, coxhdLink, coxhdVersion, mg14Link, mg14Version);

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <Segment>
          <h1>
            Predict Blood
          </h1>
          <Divider />
          <Grid columns={2}>
            {createRow(ukcaIcon, sangerName)}
            {createRow(refIcon, 'Predict Blood')}
            {createRow(lotIcon, versionText)}
            {createRow(bookIcon, refer)}
            {createRow(factoryIcon, <span>{sangerName}, {sangerAddress}</span>)}
          </Grid>
          <List>
            {getMedicalDeviceText()}
          </List>
        </Segment>
      </Container>
      { banner }
    </div>
  );
}

ConnectedLegalPage.propTypes = {
  fetchRestVersion: PropTypes.func.isRequired,
  restVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  fetchCoxHDVersion: PropTypes.func.isRequired,
  coxhdVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  fetchmg14Version: PropTypes.func.isRequired,
  mg14Version: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

const LegalPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedLegalPage);

export default LegalPage;
