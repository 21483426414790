import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default function PrinterWrapper({ children, docTitle }) {
  const linkToPrint = () => <Button>Download Report</Button>;
  const componentRef = useRef();
  return (
    <>
      <Divider hidden />
      <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} documentTitle={docTitle} />
      <div ref={componentRef}>
        {children}
      </div>
    </>
  );
}

PrinterWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  docTitle: PropTypes.string.isRequired,
};
