import React from 'react';
import { Container } from 'semantic-ui-react';
import '../css/predictbloodheader.css';

function HeaderBand() {
  return (
    <Container className="headerband" />
  );
}

export default HeaderBand;
