export const CREATE_ERROR_MESSAGE = 'CREATE_ERROR_MESSAGE';
export const ADD_MULTIRFX5 = 'ADD_MULTIRFX5';
export const RESET_MULTIRFX5 = 'RESET_MULTIRFX5';
export const ADD_MULTIRFX5_COHORT = 'ADD_MULTIRFX5_COHORT';
export const RESET_MULTIRFX5_COHORT = 'RESET_MULTIRFX5_COHORT';
export const ADD_REST_VERSION = 'ADD_REST_VERSION';
export const ADD_COXHD_VERSION = 'ADD_COXHD_VERSION';
export const ADD_MG14_VERSION = 'ADD_MG14_VERSION';
export const CREATE_PATIENT_INPUT = 'CREATE_PATIENT_INPUT';
export const UPDATE_PATIENT_INPUT = 'UPDATE_PATIENT_INPUT';
export const RESET_PATIENT_INPUT = 'RESET_PATIENT_INPUT';
export const UPDATE_COHORT_INPUT = 'UPDATE_COHORT_INPUT';
export const RESET_COHORT_INPUT = 'RESET_COHORT_INPUT';
export const UPDATE_VALIDATION = 'UPDATE_VALIDATION';
export const RESET_VALIDATION = 'RESET_VALIDATION';
export const UPDATE_COHORT_VALIDATION = 'UPDATE_COHORT_VALIDATION';
export const RESET_COHORT_VALIDATION = 'RESET_COHORT_VALIDATION';
export const UPDATE_DIPSS = 'UPDATE_DIPSS';
export const RESET_DIPSS = 'RESET_DIPSS';
export const UPDATE_DIPSS_VALIDATION = 'UPDATE_DIPSS_VALIDATION';
export const RESET_DIPSS_VALIDATION = 'RESET_DIPSS_VALIDATION';
export const CREATE_GENOMICS_INPUT = 'CREATE_GENOMICS_INPUT';
export const UPDATE_GENOMICS_INPUT = 'UPDATE_GENOMICS_INPUT';
export const ADD_TGSGENES = 'ADD_TGSGENES';
export const ADD_TGSGENESINFO = 'ADD_TGSGENESINFO';
export const ADD_MPNINPUT = 'ADD_MPNINPUT';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_GENE_PANEL = 'UPDATE_GENE_PANEL';
export const TOGGLE_GENE_PANEL = 'TOGGLE_GENE_PANEL';
export const RESET_GENE_PANEL = 'RESET_GENE_PANEL';
export const UPDATE_GENES_TABLE = 'UPDATE_GENES_TABLE';
export const RESET_GENES_TABLE = 'RESET_GENES_TABLE';
export const ADD_USER_MESSAGE = 'ADD_USER_MESSAGE';
