import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ModalInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClose = (e) => {
    e.preventDefault();
    this.setState(() => ({ open: false }));
  };

  handleOpen = (e) => {
    e.preventDefault();
    this.setState(() => ({ open: true }));
  };

  render() {
    const { header, content } = this.props;
    const { open } = this.state;
    return (
      <Modal
        closeIcon
        open={open}
        trigger={<Button id={`${header} trigger`} circular icon color="blue" size="mini"><Icon name="info" /></Button>}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
      >
        <Header content={header} />
        <Modal.Content>
          {content}
        </Modal.Content>
        <Modal.Actions>
          <Button id="close" color="blue" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ModalInformation.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.shape({}).isRequired,
};

export default ModalInformation;
