import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Popup } from 'semantic-ui-react';

import { renderError } from './renderError';

function GridLabeledInput(
  {
    help,
    label,
    labelWidth,
    component,
    componentWidth,
    component2,
    component2Width,
    errorMessage,
    errorWidth,
    tooltip,
  }) {
  let hideTooltip = true;
  if (tooltip) {
    hideTooltip = false;
  }

  return (
    <Grid.Row>
      <Grid.Column width={1} verticalAlign="middle">
        {help}
      </Grid.Column>
      <Grid.Column computer={labelWidth} tablet={14} mobile={15} verticalAlign="middle">
        {label}
      </Grid.Column>
      <Popup
        content={tooltip}
        position="top right"
        disabled={hideTooltip}
        trigger={(
          <Grid.Column computer={componentWidth} tablet={16} mobile={16}>
            { component }
          </Grid.Column>
        )}
      />
      { component2Width && (
        <Grid.Column width={component2Width}>
          { component2 }
        </Grid.Column>
      )}
      <Grid.Column width={errorWidth}>
        {errorMessage && renderError(errorMessage)}
      </Grid.Column>
    </Grid.Row>
  );
}

GridLabeledInput.propTypes = {
  help: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  labelWidth: PropTypes.number.isRequired,
  component: PropTypes.node.isRequired,
  componentWidth: PropTypes.number.isRequired,
  component2: PropTypes.node,
  component2Width: PropTypes.number,
  errorMessage: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }),
  errorWidth: PropTypes.number,
  tooltip: PropTypes.string,
};

GridLabeledInput.defaultProps = {
  errorMessage: null,
  errorWidth: null,
  component2: null,
  component2Width: null,
  tooltip: null,
};

export default GridLabeledInput;
