import { combineReducers } from 'redux';
import multiRFX5reducer from './multiRFX5reducer';
import multiRFX5Cohortreducer from './multiRFX5Cohortreducer';
import restVersionReducer from './restVersionReducer';
import coxhdVersionReducer from './coxhdVersionReducer';
import mg14VersionReducer from './mg14VersionReducer';
import TGSgenesReducer from './TGSgenesReducer';
import TGSgenesInfoReducer from './TGSgenesInfoReducer';
import patientInputReducer from './patientInputReducer';
import cohortInputReducer from './cohortInputReducer';
import validationReducer from './validationReducer';
import cohortValidationReducer from './cohortValidationReducer';
import genomicsInputReducer from './genomicsInputReducer';
import MPNinputReducer from './MPNinputReducer';
import loadingReducer from './loadingReducer';
import DIPSSReducer from './DIPSSReducer';
import DIPSSValidationReducer from './DIPSSValidationReducer';
import genePanelReducer from './genePanelReducer';
import genesTableReducer from './genesTableReducer';
import userMessageReducer from './userMessageReducer';

const appReducer = combineReducers({
  multiRFX5: multiRFX5reducer,
  multiRFX5Cohort: multiRFX5Cohortreducer,
  restVersion: restVersionReducer,
  coxhdVersion: coxhdVersionReducer,
  mg14Version: mg14VersionReducer,
  TGSgenes: TGSgenesReducer,
  TGSgenesInfo: TGSgenesInfoReducer,
  patientInput: patientInputReducer,
  cohortInput: cohortInputReducer,
  validation: validationReducer,
  cohortValidation: cohortValidationReducer,
  genomicsInput: genomicsInputReducer,
  MPNinput: MPNinputReducer,
  loading: loadingReducer,
  DIPSS: DIPSSReducer,
  DIPSSValidation: DIPSSValidationReducer,
  genePanel: genePanelReducer,
  genesTable: genesTableReducer,
  userMessage: userMessageReducer,
});

// Reset all redux state
// See: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined; // eslint-disable-line no-param-reassign
  }
  return appReducer(state, action);
};

export default rootReducer;
