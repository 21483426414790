import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localStore from 'store';

import { Grid, Form, Divider, Segment, Checkbox, Header, Message, Container } from 'semantic-ui-react';

import StoreGenePanel from '../../components/genePanel/StoreGenePanel';
import StartButton from '../../components/StartButton';
import GridCheckbox from '../../components/GridCheckbox';
import GenePanel from '../../components/genePanel/GenePanel';
import Help from '../../components/Help';
import ResetHelpInfoText from '../../components/ResetHelpInfoText';

import '../../css/styles.css';

import {
  toggleGenePanel as actionToggleGenePanel,
  updateGenePanel as actionUpdateGenePanel,
  resetGenePanel as actionResetGenePanel,
} from '../../actions';

import {
  formValues,
  mutationGroups,
} from '../../constants/Config';

import ModalData from '../../constants/HelpText';

const mapStateToProps = (state) => ({
  genePanel: state.genePanel,
});

const mapDispatchToProps = (dispatch) => ({
  resetGenePanel: () => dispatch(actionResetGenePanel()),
  toggleGenePanel: (name, key) => dispatch(actionToggleGenePanel(name, key)),
  updateGenePanel: (name, value, key) => dispatch(actionUpdateGenePanel(name, value, key)),
});

function createGenePanelChecks(onChange, genePanel, type) {
  const rows = [];
  Object.keys(formValues).forEach((name) => {
    const gene = formValues[name];
    if (gene.type === type) {
      rows.push(
        <GridCheckbox
          label={gene.label}
          name={name}
          checked={genePanel.mutations[name]}
          onChange={onChange}
          key={name}
        />,
      );
    }
  });
  return rows;
}

export class ConnectedGenePanelNew extends React.Component {
  constructor(props) {
    super(props);

    // Initial state for storing panel
    this.state = {
      storeGenePanel: false,
      storedStatus: '', // 'exists', 'success', 'fail'
    };
  }

  // gene checkbox
  handleChange = (e, { name }) => {
    const { toggleGenePanel } = this.props;
    toggleGenePanel(name, 'mutations');
  };

  // Populate minimal gene set
  handleSuggestedSetClick = (e, { checked }) => {
    const { updateGenePanel } = this.props;
    updateGenePanel('suggestedSet', checked, 'checkbox');
    Object.keys(formValues).forEach((name) => {
      if (formValues[name].required) {
        updateGenePanel(name, checked, 'mutations');
      }
    });
  };

  // Populate Karyotypes
  handleKaryotypeClick = (e, { checked }) => {
    const { updateGenePanel } = this.props;
    updateGenePanel('karyotype', checked, 'checkbox');
    Object.keys(formValues).forEach((name) => {
      if (formValues[name].type === mutationGroups.chr.type) {
        updateGenePanel(name, checked, 'mutations');
      }
    });
  };

  // Remember me
  handleClick = (e, { checked }) => {
    this.setState({ storeGenePanel: checked });
  };

  // Store
  handleStore = (name) => {
    const { genePanel } = this.props;
    let storedStatus = '';

    // Find existing entry
    const storedGenePanel = localStore.get('prblGenePanel');

    // Check if name is already been stored
    if (storedGenePanel) {
      if (name in storedGenePanel) {
        storedStatus = 'exists';
      } else {
        storedGenePanel[name] = genePanel.mutations;
        localStore.set('prblGenePanel', storedGenePanel);
      }
    } else {
      // Store new entry
      localStore.set('prblGenePanel', { [name]: genePanel.mutations });
    }

    // Want to return some sort of acknowlegement
    const checkStore = localStore.get('prblGenePanel');

    // If haven't already set storedStatus
    if (!storedStatus) {
      if (name in checkStore) {
        storedStatus = 'success';
      } else {
        storedStatus = 'fail';
      }
    }
    this.setState({ storedStatus });
  };

  reset = (e) => {
    const { resetGenePanel } = this.props;
    resetGenePanel();
    this.setState({ storeGenePanel: false });
    e.preventDefault();
  };

  render() {
    const { genePanel, banner } = this.props;
    const { storeGenePanel, storedStatus } = this.state;
    const { primary, additional, chr } = mutationGroups;

    const checks1 = createGenePanelChecks(this.handleChange, genePanel, primary.type);
    const checks2 = createGenePanelChecks(this.handleChange, genePanel, additional.type);
    const checks3 = createGenePanelChecks(this.handleChange, genePanel, chr.type);
    const karyotypeCheckbox = (
      <Help help={ModalData.genePanelKaryotype}>
        <Checkbox
          label={{ children: 'Karyotype available', style: { fontSize: 'large' } }}
          onClick={this.handleKaryotypeClick}
          checked={genePanel.checkbox.karyotype}
          name="karyotype"
        />
      </Help>
    );
    return (
      <div>
        { banner }
        <Container className="topbotmargin">
          <Help help={ModalData.genePanelNew}>
            <Header as="h1">Create new Gene Panel</Header>
          </Help>
          <Message>
            <p className="ptext">
              Please select all the mutations and genes that have been tested for in the individual with an MPN.
              <br />
              This selection can then be saved as a custom gene panel and used on Predict Blood in the future.
            </p>
          </Message>
          <Segment>
            <ResetHelpInfoText onClick={this.reset} />
            <Help help={ModalData.genePanelSuggestedSet}>
              <Checkbox
                label={{ children: 'Use suggested standard set', style: { fontSize: 'large' } }}
                onClick={this.handleSuggestedSetClick}
                checked={genePanel.checkbox.suggestedSet}
                name="suggestedStandardSet"
              />
            </Help>
            <GenePanel checkboxes={checks1} title={primary.title} />
            <Divider hidden />
            <GenePanel checkboxes={checks2} title={additional.title} />
            <Divider hidden />
            <GenePanel checkboxes={checks3} title={chr.title} elem={karyotypeCheckbox} />
            <Divider hidden />
            <Help help={ModalData.genePanelStore}>
              <Form.Checkbox
                label={{ children: 'Remember this panel', style: { fontSize: 'large' } }}
                onClick={this.handleClick}
                checked={storeGenePanel}
                name="rememberGenePanel"
              />
            </Help>
            <Grid>
              <Grid.Column width={8}>
                <StoreGenePanel
                  disabled={!storeGenePanel}
                  onClick={this.handleStore}
                  onChange={this.handleChange}
                  storedStatus={storedStatus}
                />
              </Grid.Column>
            </Grid>
          </Segment>
          <StartButton />
        </Container>
      </div>
    );
  }
}

ConnectedGenePanelNew.propTypes = {
  toggleGenePanel: PropTypes.func.isRequired,
  updateGenePanel: PropTypes.func.isRequired,
  resetGenePanel: PropTypes.func.isRequired,
  genePanel: PropTypes.shape({
    mutations: PropTypes.shape({}),
    checkbox: PropTypes.shape({
      suggestedSet: PropTypes.bool,
      karyotype: PropTypes.bool,
    }),
  }).isRequired,
  banner: PropTypes.element.isRequired,
};

const GenePanelNew = connect(mapStateToProps, mapDispatchToProps)(ConnectedGenePanelNew);
export default GenePanelNew;
