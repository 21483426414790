import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Grid, Segment } from 'semantic-ui-react';

import InitialDiagnosis from '../components/InitialDiagnosis';
import PatientSelector from '../components/PatientSelector';
import { fieldValidation, isValidForm } from '../utils/Helpers';
import ResetHelpInfoText from '../components/ResetHelpInfoText';

import {
  updateCohortInput as actionUpdateCohortInput,
  resetCohortInput as actionResetCohortInput,
  updateCohortValidation as actionUpdateCohortValidation,
  resetCohortValidation as actionResetCohortValidation,
  fetchMPNinputId as actionFetchMPNinputId,
  fetchMultiRFX5Exist as actionFetchMultiRFX5Exist,
  resetMultiRFX5Cohort as actionResetMultiRFX5Cohort,
} from '../actions';

import {
  formValues,
} from '../constants/Config';

const mapDispatchToProps = (dispatch) => ({
  updateCohortInput: (name, value) => dispatch(actionUpdateCohortInput(name, value)),
  resetCohortInput: () => dispatch(actionResetCohortInput()),
  updateCohortValidation: (name, value) => dispatch(actionUpdateCohortValidation(name, value)),
  resetCohortValidation: () => dispatch(actionResetCohortValidation()),
  fetchMPNinputId: (data) => dispatch(actionFetchMPNinputId(data)),
  fetchMultiRFX5Exist: (data) => dispatch(actionFetchMultiRFX5Exist(data)),
  resetMultiRFX5Cohort: () => dispatch(actionResetMultiRFX5Cohort()),
});

const mapStateToProps = (state) => ({
  cohortInput: state.cohortInput,
  cohortValidation: state.cohortValidation,
  MPNinput: state.MPNinput,
  loading: state.loading,
});

export class ConnectedCohortDataForm extends React.Component {
  handleSubmit = () => {
    const { fetchMultiRFX5Exist, cohortInput } = this.props;
    // e.preventDefault();
    const patientInput = { patientID: cohortInput.patientID };

    // console.log('SUBMITTING');
    // console.log('input=', patientInput);
    fetchMultiRFX5Exist(patientInput);
  };

  handleChange = (e, { name, value }) => {
    const { updateCohortInput, updateCohortValidation, fetchMPNinputId } = this.props;
    // console.log('Updating input handleChange=', e);
    // console.log('setstate name=', name, value);

    if (name === 'initialDiagnosis') {
      // Fetch patient ids
      fetchMPNinputId(value);

      // If changing initialDiagnois, reset patientID
      updateCohortInput('patientID', '');
      updateCohortValidation('patientID', false);
    }

    // Update validation state
    // true: valid value
    // false: invalid value
    const elem = formValues[name];
    updateCohortValidation(name, fieldValidation(elem, value));
    updateCohortInput(name, value);
  };

  reset = (e) => {
    const { resetCohortInput, resetCohortValidation, resetMultiRFX5Cohort } = this.props;
    resetCohortInput();
    resetCohortValidation();
    // Remove any current plots
    resetMultiRFX5Cohort();
    e.preventDefault();
  };

  render() {
    const { MPNinput, loading, cohortInput, cohortValidation } = this.props;

    // console.log('cohortInput', cohortInput, 'validation', cohortValidation);

    // Check status of input data
    const cohortFields = ['initialDiagnosis', 'patientID'];
    // disabled is true if form is invalid ie false
    const disabled = !isValidForm(cohortFields, cohortValidation);

    const patientIds = [];
    if (MPNinput.length) {
      // Create structure for dropdown menu
      MPNinput.forEach((id) => {
        const elem = {
          key: id,
          text: id,
          value: id,
        };
        patientIds.push(elem);
      });
    }
    // console.log('MPNinput', MPNinput);

    return (
      <Form onSubmit={this.handleSubmit}>
        <Segment>
          <ResetHelpInfoText onClick={this.reset} />
          <Grid columns={2}>
            <InitialDiagnosis onChange={this.handleChange} patientInput={cohortInput} validation={cohortValidation} />
            <PatientSelector
              label="Cohort patient id"
              onChange={this.handleChange}
              patientIds={patientIds}
              defaultValue={cohortInput.patientID}
              showBorder={!cohortValidation.patientID}
            />
          </Grid>
          <Segment basic textAlign="center">
            <Form.Button
              loading={loading}
              disabled={disabled}
              content="Show outcome for this patient"
              primary
            />
          </Segment>
        </Segment>
      </Form>
    );
  }
}

ConnectedCohortDataForm.propTypes = {
  fetchMPNinputId: PropTypes.func.isRequired,
  fetchMultiRFX5Exist: PropTypes.func.isRequired,
  resetMultiRFX5Cohort: PropTypes.func.isRequired,
  updateCohortInput: PropTypes.func.isRequired,
  resetCohortInput: PropTypes.func.isRequired,
  updateCohortValidation: PropTypes.func.isRequired,
  resetCohortValidation: PropTypes.func.isRequired,
  MPNinput: PropTypes.arrayOf(PropTypes.string).isRequired,
  cohortInput: PropTypes.shape({
    patientID: PropTypes.string,
    initialDiagnosis: PropTypes.string,
  }).isRequired,
  cohortValidation: PropTypes.shape({
    patientID: PropTypes.bool,
    initialDiagnosis: PropTypes.bool,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const CohortDataForm = connect(mapStateToProps, mapDispatchToProps)(ConnectedCohortDataForm);

export default CohortDataForm;
