import React from 'react';
import { Image } from 'semantic-ui-react';

import StartButton from '../StartButton';
import '../../css/styles.css';
import graphIcon from '../../images/graph-icon.png';

function UsePredict() {
  return (
    <div>
      <Image src={graphIcon} />
      <h2 id="usepredict">
        Want to use Predict?
      </h2>
      <p className="ptext">This tool aims to help estimate disease progression and patient survival.</p>
      <StartButton />
    </div>
  );
}

export default UsePredict;
