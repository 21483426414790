import update from 'immutability-helper';

import {
  UPDATE_VALIDATION,
  RESET_VALIDATION,
} from '../actions/types';

import {
  requiredPatientInputFields,
} from '../constants/Config';

function initialiseState() {
  const result = {};
  requiredPatientInputFields.forEach((elem) => {
    result[elem] = false;
  });
  return result;
}

function validation(
  state = initialiseState(),
  action = {}) {
  // console.log('reducer validation', action);
  // console.log('state=', state);
  switch (action.type) {
    case UPDATE_VALIDATION:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_VALIDATION:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default validation;
