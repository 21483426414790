import update from 'immutability-helper';

import {
  UPDATE_GENES_TABLE,
  RESET_GENES_TABLE,
} from '../actions/types';

function initialiseState() {
  const result = {
    panelName: '',
    geneList: {},
  };
  return result;
}

function genesTable(
  state = initialiseState(),
  action = {}) {
  switch (action.type) {
    case UPDATE_GENES_TABLE:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_GENES_TABLE:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default genesTable;
