import {
  ADD_TGSGENES,
} from '../actions/types';

function TGSgenes(state = {}, action = {}) {
  switch (action.type) {
    case ADD_TGSGENES:
      return action.payload;
    default:
      return state;
  }
}

export default TGSgenes;
