/* eslint-disable no-unused-vars */
import React, { createRef } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import { Sticky } from 'semantic-ui-react';

import HomePage from './HomePage';
import AboutPage from './AboutPage';
import DefaultPage from './DefaultPage';
import PredictBloodLogos from '../components/PredictBloodLogos';
import UserMessage from '../components/UserMessage';
import PredictBloodHeader from '../components/PredictBloodHeader';
import PredictBloodPage from './PredictBloodPage';
import CohortDataPage from './CohortDataPage';
import CohortMutationPage from './CohortMutationPage';
import ContactPage from './ContactPage';
import LegalPage from './LegalPage';
import GenePanelNew from './genePanel/GenePanelNew';
import GenePanelView from './genePanel/GenePanelView';
import GenePanelDelete from './genePanel/GenePanelDelete';
import DisclaimerPage from '../components/legal/DisclaimerPage';
import AlgorithmPage from '../components/legal/AlgorithmPage';
import PrivacyPage from '../components/legal/PrivacyPage';
import OverviewPage from '../components/about/OverviewPage';
import TechnicalPage from '../components/about/TechnicalPage';
import FAQPage from '../components/about/FAQPage';
import ReleaseNotesPage from '../components/about/ReleaseNotesPage';
import Footer from '../components/footer/Footer';

import HeaderBand from '../components/HeaderBand';
import PredictBloodToolBanner from '../components/PredictBloodToolBanner';

import FeedbackButton from '../components/FeedbackButton';

import InfoBanner from '../components/footer/InfoBanner';
import IntroBanner from '../components/footer/IntroBanner';

function App() {
  const contextRef = createRef();
  const headerBand = (<HeaderBand />);
  const bannerInfo = (<InfoBanner />);
  const bannerIntro = (<IntroBanner />);
  const predictBloodToolBanner = (
    <PredictBloodToolBanner
      text="We recommend that this tool is used by or in conjunction with a suitably qualified health professional"
    />
  );
  const cohortDataBanner = (<PredictBloodToolBanner text="" />);

  return (
    <Router>
      <div ref={contextRef}>
        <PredictBloodLogos />
        <UserMessage />
        <Sticky context={contextRef}>
          <PredictBloodHeader />
        </Sticky>
        <Routes>
          <Route path="/" exact element={(<HomePage banner={bannerIntro} />)} />
          <Route
            path="/about"
            exact
            element={(<AboutPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route path="/about/overview" element={(<OverviewPage banner={bannerInfo} />)} />
          <Route
            path="/about/technical"
            element={(<TechnicalPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/about/releasenotes"
            element={(<ReleaseNotesPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/about/faq"
            element={(<FAQPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/tool"
            element={(<PredictBloodPage banner={predictBloodToolBanner} />)}
          />
          <Route
            path="/gene/new"
            element={(<GenePanelNew banner={cohortDataBanner} />)}
          />
          <Route
            path="/gene/view"
            element={(<GenePanelView banner={cohortDataBanner} />)}
          />
          <Route
            path="/gene/delete"
            element={(<GenePanelDelete banner={cohortDataBanner} />)}
          />
          <Route
            path="/cohort"
            element={(<CohortDataPage banner={cohortDataBanner} />)}
          />
          <Route
            path="/mutations"
            element={(<CohortMutationPage banner={cohortDataBanner} />)}
          />
          <Route
            path="/contact"
            exact
            element={(<ContactPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/legal"
            exact
            element={(<LegalPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/legal/disclaimer"
            element={(<DisclaimerPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route
            path="/legal/privacy"
            element={(<PrivacyPage banner={bannerInfo} headerBand={headerBand} />)}
          />
          <Route path="*" element={<DefaultPage />} />
        </Routes>
        <Footer />
        <FeedbackButton />
      </div>
    </Router>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

export default App;
