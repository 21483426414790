import update from 'immutability-helper';

import {
  ADD_MULTIRFX5,
  RESET_MULTIRFX5,
} from '../actions/types';

function multiRFX5(state = {}, action = {}) {
  switch (action.type) {
    case ADD_MULTIRFX5:
    // console.log('PAYLOAD');
    // console.log('Reducer multiRFX5', action.payload);
      return action.payload;
    case RESET_MULTIRFX5:
      return update(state, {
        $set: {},
      });
    default:
      return state;
  }
}

export default multiRFX5;
