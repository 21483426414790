import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Dropdown } from 'semantic-ui-react';

import '../css/styles.css';
import '../css/predictbloodheader.css';
import ResponsiveMenu from './responsiveMenu/ResponsiveMenu';

function PredictBloodHeader() {
  const leftItems = [
    (
      <Link to="/" className="item" key="home">
        Home
      </Link>
    ),
    (
      <Dropdown item text="About Predict Blood" key="about">
        <Dropdown.Menu>
          <Dropdown.Item text="Overview" as={Link} to="/about" />
          <Dropdown.Item icon="angle right" text="Who is it for?" as={Link} to="/about#whoisitfor" />
          <Dropdown.Divider />
          <Dropdown.Item text="Technical" as={Link} to="/about/technical" />
          <Dropdown.Item
            icon="angle right"
            text="Website development history"
            as={Link}
            to="/about/technical#history"
          />
          <Dropdown.Divider />
          <Dropdown.Item text="Release Notes" as={Link} to="/about/releasenotes" />
          <Dropdown.Divider />
          <Dropdown.Item text="FAQs" as={Link} to="/about/faq" />
        </Dropdown.Menu>
      </Dropdown>
    ),
    (
      <Link to="/tool" className="item" key="tool">
        Predict Blood Tool
      </Link>
    ),
    (
      <Dropdown item text="Gene Panel" key="genepanel">
        <Dropdown.Menu>
          <Dropdown.Item text="New" as={Link} to="/gene/new" />
          <Dropdown.Item text="View" as={Link} to="/gene/view" />
          <Dropdown.Item text="Delete" as={Link} to="/gene/delete" />
        </Dropdown.Menu>
      </Dropdown>
    ),
    (
      <Link to="/cohort" className="item" key="cohortdata">
        Cohort Data
      </Link>
    ),
    (
      <Link to="/mutations" className="item" key="cohortmutations">
        Cohort Mutations
      </Link>
    ),
    (
      <Link to="/contact" className="item" key="contact">
        Contact
      </Link>
    ),
    (
      <Dropdown item text="Legal" key="legal">
        <Dropdown.Menu>
          <Dropdown.Item text="Predict Blood" as={Link} to="/legal" />
          <Dropdown.Item text="Terms of Use" as={Link} to="/legal/disclaimer" />
          <Dropdown.Item text="Site privacy" as={Link} to="/legal/privacy" />
        </Dropdown.Menu>
      </Dropdown>
    ),
  ];

  return (
    <div>
      <ResponsiveMenu leftItems={leftItems} />
    </div>
  );
}

export default PredictBloodHeader;
