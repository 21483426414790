import React from 'react';
import { Image } from 'semantic-ui-react';

import '../../css/styles.css';
import graphIcon from '../../images/graph-icon.png';

function WhatDoesPredictDo() {
  return (
    <div>
      <Image src={graphIcon} />
      <h2 id="whatdoespredictdo">
        What does Predict do?
      </h2>
      <p className="ptext">
        Predict asks for details about the patient (including genetic test results if available) and the blood cancer.
        It then uses data about the progression of disease and survival of similar patients in the past to show the
        likely proportion of patients who survive, and develop disease progression, up to 20 years from diagnosis.
        Predict Blood does not take account of different treatments and is not intended to determine treatment
        decisions.
      </p>
    </div>
  );
}

export default WhatDoesPredictDo;
