import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import '../css/styles.css';
import ButtonRow from './ButtonRow';
import { outcomes, yearText } from '../constants/Config';
import { format, parseRisk } from '../utils/Helpers';

function generateText(risk, years) {
  const riskFactors = parseRisk(risk);
  const { outcome, percentage } = riskFactors;
  const numPatients = Math.round(percentage);

  const totalPatients = 100;
  const text = format(outcomes[outcome].text, numPatients, totalPatients, years);
  return ([outcome, text]);
}

class ResultText extends React.Component {
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      years: '',
    };
  }

  onClick = (e, value) => {
    this.setState({ years: value.value });
  };

  render() {
    const { multiRFX5 } = this.props;
    const { years } = this.state;

    // console.log('PatientDescription', multiRFX5);
    // Return if no data
    if (_.isEmpty(multiRFX5)) {
      return (<div />);
    }
    const text = [];
    if (years) {
      multiRFX5[yearText[years]].forEach((risk) => {
        const [outcome, riskText] = generateText(risk, years);
        text.push(riskText, <br key={outcome} />);
      });
    }
    const buttons = ['5', '10', '20'];
    return (
      <div>
        <p className="ptext">
          Select number of years since diagnosis you wish to consider:
        </p>
        <ButtonRow
          buttonList={buttons}
          showBorder={false}
          setValue={(e, value) => this.onClick(e, { value })}
          value={years}
          name="outcome"
        />
        <Message id="textMessage">
          <p className="ptext">
            {text}
          </p>
          <p className="ptext">
            {multiRFX5.MEFS}
          </p>
        </Message>

      </div>
    );
  }
}

ResultText.propTypes = {
  multiRFX5: PropTypes.shape({
    plot: PropTypes.arrayOf(PropTypes.shape({})),
    line: PropTypes.arrayOf(PropTypes.shape({})),
    fiveyr: PropTypes.arrayOf(PropTypes.string),
    tenyr: PropTypes.arrayOf(PropTypes.string),
    twentyyr: PropTypes.arrayOf(PropTypes.string),
    MEFS: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default ResultText;
