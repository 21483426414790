import update from 'immutability-helper';

import {
  TOGGLE_GENE_PANEL,
  UPDATE_GENE_PANEL,
  RESET_GENE_PANEL,
} from '../actions/types';

import {
  formValues,
  mutationGroups,
} from '../constants/Config';

function initialiseState() {
  const result = {
    mutations: {},
    checkbox: {
      suggestedSet: false,
      karyotype: false,
    },
  };
  const { primary, additional, chr } = mutationGroups;
  Object.keys(formValues).forEach((elem) => {
    if (formValues[elem].type === primary.type
        || formValues[elem].type === additional.type
        || formValues[elem].type === chr.type) {
      result.mutations[elem] = false;
    }
  });

  // console.log(result);
  return result;
}

function genePanel(
  state = initialiseState(),
  action = {}) {
  switch (action.type) {
    case TOGGLE_GENE_PANEL:
      return update(state, {
        [action.key]: { [action.name]: { $set: !(state[action.key][action.name]) } },
      });
    case UPDATE_GENE_PANEL:
      return update(state, {
        [action.key]: { [action.name]: { $set: action.value } },
      });
    case RESET_GENE_PANEL:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default genePanel;
