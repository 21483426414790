import React from 'react';
import { connect } from 'react-redux';
import { Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  fetchUserMessage as actionFetchUserMessage,
} from '../actions';

import {
  timeIntervalInMinutes,
  userMessageColor,
  validSegmentColors,
} from '../constants/Config';

const mapDispatchToProps = (dispatch) => ({
  fetchUserMessage: () => dispatch(actionFetchUserMessage()),
});

const mapStateToProps = (state) => ({
  userMessage: state.userMessage,
});

export class ConnectedUserMessage extends React.Component {
  componentDidMount() {
    const { fetchUserMessage } = this.props;

    // Convert into milliseconds
    const intervalTime = timeIntervalInMinutes * 60 * 1000;

    // Show message the first time
    fetchUserMessage();

    // Poll for any new messages every timeIntervalInMinutes
    setInterval(() => {
      fetchUserMessage();
    }, intervalTime);
  }

  render() {
    const { userMessage } = this.props;

    if (_.isEmpty(userMessage)) {
      return (<div />);
    }

    const { message, color } = userMessage;

    // If no message declared, do not render banner
    if (!message) {
      return (<div />);
    }

    // If no color declared or entered a not supported colour, use default colour
    let bgColor = color;
    if (!color || !validSegmentColors.includes(color)) {
      bgColor = userMessageColor;
    }

    return (
      <div>
        <Segment textAlign="center" inverted color={bgColor}>{message}</Segment>
      </div>
    );
  }
}
const UserMessage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserMessage);

ConnectedUserMessage.propTypes = {
  fetchUserMessage: PropTypes.func.isRequired,
  userMessage: PropTypes.shape({
    message: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
};

export default UserMessage;
