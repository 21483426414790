import React from 'react';
import '../css/styles.css';
import { Icon, Container } from 'semantic-ui-react';

function PredictBloodToolInfoText() {
  const button = <Icon name="info circle" color="blue" size="large" />;
  return (
    <Container text>
      <p className="ptext">
        If you are unsure of any inputs or outputs, click on the {button} buttons for more information.
      </p>
    </Container>
  );
}

export default PredictBloodToolInfoText;
