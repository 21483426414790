import React from 'react';
import { Image } from 'semantic-ui-react';

import bloodIcon from '../images/predict_blood_17.png';
import '../css/predictbloodlogos.css';

function PredictBloodLogos() {
  return (
    <div>
      <Image src={bloodIcon} size="small" />
    </div>
  );
}

export default PredictBloodLogos;
