import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

function MPNPlotDesktop({ results, layout, style, annotations }) {
  const newLayout = layout;
  newLayout.annotations = annotations;

  return (
    <div>
      <Plot
        data={results}
        layout={newLayout}
        style={style}
        useResizeHandler
      />
    </div>
  );
}

MPNPlotDesktop.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layout: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
  annotations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default MPNPlotDesktop;
