import React, { useLayoutEffect } from 'react';
import { Container, Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import '../../css/styles.css';
import {
  mpnvoiceURL,
  bloodcancerURL,
  MPNPublication,
} from '../../constants/Config';
import WhoBuiltPredictBlood from './WhoBuiltPredictBlood';

function FAQPage({ banner, headerBand }) {
  // Scroll to top of page when linking
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1 id="faq">
          FAQs
        </h1>
        <h2 id="lookadvice">
          Looking for advice?
        </h2>
        <p className="ptext">
          Further information about myeloproliferative disorders is available at:
          <br />
          <a
            href={mpnvoiceURL}
            target="_blank"
            rel="noreferrer noopener"
          > MPN Voice
          </a>
          <br />
          <a
            href={bloodcancerURL}
            target="_blank"
            rel="noreferrer noopener"
          > Blood Cancer UK
          </a>
        </p>
        <Divider />
        <h2>
          What if I don’t have all the details needed for the input section?
        </h2>
        <p className="ptext">
          The model can still be used if you are missing some of the details requested by the
          Predict Blood tool.  Input boxes left blank or genes not selected in a gene panel will
          be treated as not available.  The model uses the data from the initial cohort it was built
          on to generate an average value which will be used in place of the data that is not available.
          <br />
          Whilst the estimates may still be useful, this step may reduce the accuracy of the model output.
        </p>
        <Divider />
        <h2>
          How do I know that Predict Blood gives helpful information?
        </h2>
        <p className="ptext">
          Predict Blood estimates what would be expected to happen to individuals with MPNs based on past data.
          The model was built using data from 2035 patients. The number of
          patients included was chosen based on statistical calculations that suggested that this number would be
          sufficient to identify genetic mutations that were affecting patient outcome.  These patients were
          followed up over many years and information about what actually happened to their MPN was collected.  This
          information was used to build a model.
          <br />
          Predict Blood was then used to generate outcome predictions for a second cohort of 515 Italian patients,
          these patients had been seen by different doctors and their diagnostic tests were done at a separate
          hospital. There was also follow-up data available allowing comparison between the model prediction and
          what actually happened. In both the British and Italian cohorts, Predict Blood was accurate in 80% of cases.
          <br />
          Predict Blood produces average estimates based on what happened to groups of individuals with MPNs
          with similar characteristics to those inputted into the model.  This can be a helpful guideline but
          it is not predicting what will happen to a specific individual.
          <br />
          Please see the <Link to="/about/technical">Technical</Link> section and
          the <a href={MPNPublication} target="_blank" rel="noopener noreferrer">publication</a> for
          further information.
        </p>
        <Divider />
        <h2>
          What if the diagnosis predates the genetic results?
        </h2>
        <p className="ptext">
          Predict Blood was built on a cohort of patients who had genetic results available within a year of diagnosis.
          However often these tests are carried out at a later stage.  If using Predict Blood with results obtained
          much later than diagnosis we suggest using the age at the time of diagnosis and the blood results as close to
          diagnosis as possible.
        </p>
        <Divider />
        <h2>
          Which initial diagnosis should I choose for a patient who has progressed from chronic phase MPN (PV, ET)
          to MF?
        </h2>
        <p className="ptext">
          When using Predict Blood for patients with post-ET or post-PV myelofibrosis, select myelofibrosis as the
          initial diagnosis and input the patient’s age at the time of disease progression.  Predict Blood will
          generate predictions for the patient’s myelofibrosis.  These predictions will be based on all
          patients with myelofibrosis in the original cohort with no distinction made between those which where primary
          and secondary.
        </p>
        <Divider />
        <h2>
          Can I use Predict Blood for patients with an MPN other than PV, ET or MF?
        </h2>
        <p className="ptext">
          The World Health Organisation 2016 classification of haematological malignancy included a Myeloproliferative
          Neoplasm Unclassifiable category for myeloproliferative neoplasms which are not classifiable in one of the
          other categories.  The cohort of patients that Predict Blood was developed on included a small number of
          patients in this category, but far less than the number of patients with other MPNs.  Therefore, Predict
          Blood can be used on MPNu patients but the predictions may be less accurate.
          <br />
          Predict Blood was not developed for use in individuals with MPN/MDS overlap syndromes and should not be used
          in this context.
        </p>
        <Divider />
        <h2>
          Why does Predict Blood not include which treatment the patient is having?
        </h2>
        <p className="ptext">
          The current treatment for MPNs is given to reduce the risk of arterial or venous clot formation.
          This includes antiplatelet agents, anticoagulation and cytoreduction.  Cytoreduction can be medications
          or venesection and reduces clot risk by decreasing the haematocrit and platelet count.  These treatments
          do not affect progression of disease to myelofibrosis or acute myeloid leukaemia, and will not influence
          the risk associated with particular mutated genes.
        </p>
        <Divider />
        <WhoBuiltPredictBlood />
      </Container>
      { banner }
    </div>
  );
}

FAQPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default FAQPage;
