import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

function TGSgenesText({ TGSgene, gene }) {
  // console.log('TGSgenesText', TGSgene, gene);

  // Return if no data
  if (_.isEmpty(TGSgene)) {
    return (<div />);
  }

  // HACK need to position this better
  return (
    <div>
      <p>Present in {TGSgene.cohort_presence[0]}% of cohort.</p>
      <p>({TGSgene.percent_male[0]}% Male, Median age at diagnosis {TGSgene.median_age_at_diagnosis[0]} years) </p>
      <p> Mean number of mutations in patients with {gene}: {TGSgene.mean_num_muts[0]}
      </p>
    </div>
  );
}

TGSgenesText.propTypes = {
  TGSgene: PropTypes.shape({
    cohort_presence: PropTypes.arrayOf(PropTypes.number),
    mean_num_muts: PropTypes.arrayOf(PropTypes.number),
    median_age_at_diagnosis: PropTypes.arrayOf(PropTypes.number),
    percent_male: PropTypes.arrayOf(PropTypes.number),
  }),
  gene: PropTypes.string.isRequired,
};

TGSgenesText.defaultProps = {
  TGSgene: {},
};

export default TGSgenesText;
