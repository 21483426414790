import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function StartButton() {
  return (
    <Button size="big" as={Link} to="/tool" primary name="startbutton">
      <Icon name="angle right" />Start Predict Blood
    </Button>
  );
}

export default StartButton;
