import React from 'react';

import {
  formValues,
} from './Config';

const ModalData = {
  patientID: {
    Header: 'Patient ID',
    Content:
  <p>
    Enter patient identifier if you would like this to be displayed on the report.
  </p>,
  },
  initialDiagnosis: {
    Header: 'Initial Diagnosis',
    Content:
  <p>
    Select ET (Essential Thrombocythemia), PV (Polycythemia Vera), Primary or Secondary MF (Myelofibrosis) or
    Other (for other MPN that is not consistent with ET. PV or MF).
  </p>,
  },
  Age: {
    Header: 'Age at Diagnosis',
    Content:
  <p>
    The age when the MPN was diagnosed in years
    <br /><br />
    The age should be between {formValues.Age.min} and {formValues.Age.max} years old
    <br /><br />
    If secondary MF use the age at which initial MPN was diagnosed
    <br /><br />
    Either type in the number or use the &#39;+&#39; or &#39;-&#39; buttons to adjust it.
    You can also use the up and down arrow keys to step by 1.
  </p>,
  },
  Hb: {
    Header: 'Haemoglobin',
    Content:
  <p>
    Haemoglobin level at diagnosis (g/l)  e.g. 128
    <br /><br />
    The amount should be between {formValues.Hb.min} and {formValues.Hb.max} g/l
    <br /><br />
    Either type in the number or use the &#39;+&#39; or &#39;-&#39; buttons to adjust it.
    You can also use the up and down arrow keys to step by 1
  </p>,
  },
  WCC: {
    Header: 'White Cell Count',
    Content:
  <p>
    White cell count at diagnosis (x10⁹/l) e.g. 7.8
    <br /><br />
    The count should be between {formValues.WCC.min} and {formValues.WCC.max} x10⁹/l
    <br /><br />
    Either type in the number or use the &#39;+&#39; or &#39;-&#39; buttons to adjust it.
    You can also use the up and down arrow keys to step by 1
  </p>,
  },
  Pl: {
    Header: 'Platelet Count',
    Content:
  <p>
    Platelet count at diagnosis (x10⁹/l) e.g. 772
    <br /><br />
    The count should be between {formValues.Pl.min} and {formValues.Pl.max} x10⁹/l
    <br /><br />
    Either type in the number or use the &#39;+&#39; or &#39;-&#39; buttons to adjust it.
    You can also use the up and down arrow keys to step by 1
  </p>,
  },
  Sex: {
    Header: 'Gender',
    Content:
  <p>
    Patient Gender
  </p>,
  },
  PriorThrom: {
    Header: 'Prior Thrombosis',
    Content:
  <p>
    Click Yes if the patient has had a blood clot, such as an arterial (myocardial infarction, stroke, TIA, PVD) or
    venous thromboembolism (DVT, PE) and it is likely their MPN contributed to this.
  </p>,
  },
  Splen: {
    Header: 'Splenomegaly',
    Content:
  <p>
    Click Yes if there is an enlarged spleen, such as palpable or radiological splenomegaly (&gt;11cm).
  </p>,
  },
  MolecularData: {
    Header: 'Molecular Data',
    Content:
  <p>
    Select from the dropdown list of your previously saved gene panels.
    <br />
    Go to the Gene panel page to generate and save a gene panel.
    <br />
    By choosing the <i>current</i> option Predict blood will use the mutations and genes currently ticked on the
    gene panel page.
    <br />
    Predict blood treats not available and negative results differently and therefore needs to know what results you
    have available.
  </p>,
  },
  PrimaryMutations: {
    Header: 'Primary Driver Mutation',
    Content:
  <p>
    Select the positive primary driver mutations in this patient, any unselected mutation will be treated as negative.
    <br />
    If you can’t find the required mutation, check it is included in the selected gene panel.
  </p>,
  },
  AdditionalMutations: {
    Header: 'Additional Mutations',
    Content:
  <p>
    Select the genes in which additional mutations where identified.
  </p>,
  },
  ChrAbnormalities: {
    Header: 'Chromosomal Abnormalities',
    Content:
  <p>
    Select the chromosomal abnormalities present
  </p>,
  },
  CohortPatientID: {
    Header: 'Cohort Patient ID',
    Content:
  <p>
    Select a patient from the drop-down list to see the clinical description and predictions.
  </p>,
  },
  CohortMutation: {
    Header: 'Cohort Mutation',
    Content:
  <p>
    Cohort Mutation Content
  </p>,
  },
  dAge: {
    Header: 'Age',
    Content:
  <p>
    Age Content
  </p>,
  },
  dHb: {
    Header: 'Haemoglobin',
    Content:
  <p>
    Haemoglobin Content
  </p>,
  },
  dWCC: {
    Header: 'White cell counts',
    Content:
  <p>
    White cell counts Content
  </p>,
  },
  dPBB: {
    Header: 'Peripheral blood blasts',
    Content:
  <p>
    Peripheral blood blasts Content
  </p>,
  },
  dCS: {
    Header: 'Constitutional symptoms',
    Content:
  <p>
    Constitutional symptoms Content
  </p>,
  },
  genePanelNew: {
    Header: 'Create New Gene Panel',
    Content:
  <p>
    Please select all the mutations and genes that have been tested for in the individual with an MPN.
    This selection can then be saved as a custom gene panel and used on Predict Blood in the future.
    <br />
    This step is required as the model handles not available results differently from negative results,
    so needs to know which genetics test results you have. Any unselected genes will be assigned not available
    and the model will impute averages based on …
  </p>,
  },
  genePanelSuggestedSet: {
    Header: 'Set Suggested Standard Set',
    Content:
  <p>
    Tick this box to populate the gene panel with the genes in the model that are in the
    NHS England’s 2021/2022 National Genomic Test Directory recommended myeloid gene panel.
    <br />
    Additional genes can then be added to this.
  </p>,
  },
  genePanelKaryotype: {
    Header: 'Karyotype Available',
    Content:
  <p>
    Tick this box if the individual with an MPN has karyotype results available
    (or select individual chromosome abnormalities that have been tested for).
  </p>,
  },
  genePanelStore: {
    Header: 'Remember This Panel',
    Content:
  <p>
    Tick this box if you would like to save this panel, it will then be available
    for you to use on Predict Blood in the future.
  </p>,
  },
  genePanelDelete: {
    Header: 'Delete Gene Panel',
    Content:
  <p>
    Select the saved gene panels from the drop-down list that you would like to delete
  </p>,
  },
  genePanelView: {
    Header: 'View Gene Panel',
    Content:
  <p>
    Select a saved gene panels from the drop-down list to view the genetic tests that it contains
  </p>,
  },
};

export default ModalData;
