import React from 'react';
import { Divider, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import localStore from 'store';

import GenesSelector from './GenesSelector';
import GenePanelSelector from './genePanel/GenePanelSelector';
import '../css/genestable.css';

import {
  formValues,
  geneState,
  mutationGroups,
} from '../constants/Config';

import {
  updateGenesTable as actionUpdateGenesTable,
  resetGenesTable as actionResetGenesTable,
} from '../actions';

const mapDispatchToProps = (dispatch) => ({
  updateGenesTable: (name, value) => dispatch(actionUpdateGenesTable(name, value)),
  resetGenesTable: () => dispatch(actionResetGenesTable()),
});

const mapStateToProps = (state) => ({
  genesTable: state.genesTable,
});

function findSelected(input, genes, state) {
  const selected = [];

  Object.keys(genes).forEach((gene) => {
    if (input[gene] === state) {
      selected.push(gene);
    }
  });
  return selected;
}

function isGenePanelEmpty(genes) {
  let isEmpty = true;
  Object.keys(genes).forEach((gene) => {
    if (genes[gene]) {
      isEmpty = false;
    }
  });
  return isEmpty;
}

export class ConnectedGenesTable extends React.Component {
  componentDidMount() {
    const { genePanel, genesTable, updateGenesTable } = this.props;

    // If no panelName currently selected, default to current
    if (!(genesTable.panelName)) {
      updateGenesTable('panelName', 'current');
    }

    // Update genesTable.geneList with current genePanel
    if (!(genesTable.panelName) || genesTable.panelName === 'current') {
      const thisGeneList = this.createGeneList(genePanel.mutations);
      updateGenesTable('geneList', thisGeneList);
    }
  }

  handleGenePanelChange = (e, { value }) => {
    const { genePanel, updateGenesTable } = this.props;
    let thisGeneList = {};
    const storedGenePanels = localStore.get('prblGenePanel');
    if (value === 'current') {
      thisGeneList = this.createGeneList(genePanel.mutations);
    } else {
      thisGeneList = this.createGeneList(storedGenePanels[value]);
    }

    updateGenesTable('panelName', value);
    updateGenesTable('geneList', thisGeneList);
  };

  handlePresentChange = (e, { value }) => {
    const { onGeneChange } = this.props;
    // Call onGeneChange with each selected gene (update in redux)
    value.forEach((gene) => {
      onGeneChange({ name: [gene], value: geneState.present.value });
    });
  };

  // Click anywhere on selected item label except delete icon (not used)
  /*
  handleLabelClick = (e, { value }) => {
    const { onChange } = this.props;
    const { absent } = geneState;
    const elem = {
      name: value,
      value: absent.value,
    };
    onChange(e, elem);
  };
  */

  // Click on the selected item delete icon
  handleDeleteClick = (e) => {
    const { onChange } = this.props;
    const { absent } = geneState;
    if (e.target.matches('i.delete.icon')) {
      const value = e.target.parentElement.getAttribute('value');
      onChange(e, { name: value, value: absent.value });
    }
  };

  /*
   * list: all genes with genes in genePanel set to true
   * onGeneChange: only change gene values in patientInput redux
   */
  createGeneList(list) {
    const { onGeneChange } = this.props;
    const geneList = {};

    Object.keys(list).forEach((gene) => {
      // Select genes set to 'absent' (in genePanel)
      if (list[gene]) {
        const { type, label } = formValues[gene];

        // Create 3 lists for primary, additional, chr dropdowns
        if (type in geneList) {
          geneList[type].push({ key: gene, value: gene, text: label });
        } else {
          // Initialise geneList[type]
          geneList[type] = [];
          geneList[type].push({ key: gene, value: gene, text: label });
        }
        onGeneChange({ name: [gene], value: geneState.absent.value });
      } else {
        // if not in panel, set to unknown
        onGeneChange({ name: [gene], value: geneState.unknown.value });
      }
    });
    return geneList;
  }

  render() {
    const { input, genesTable, genePanel } = this.props;
    const { geneList, panelName } = genesTable;
    const { primary, additional, chr } = mutationGroups;

    const storedGenePanels = localStore.get('prblGenePanel');
    // Check if any mutations in 'current' genePanel
    let panelNames = [];
    if (!isGenePanelEmpty(genePanel.mutations)) {
      panelNames = [{ key: 'current', value: 'current', text: 'current' }];
    }

    // Add any stored GenePanels
    if (storedGenePanels) {
      Object.keys(storedGenePanels).forEach((panel) => {
        panelNames.push({ key: panel, value: panel, text: panel });
      });
    }

    const selected = findSelected(input, genePanel.mutations, geneState.present.value);
    /*
      When have controlled component (ie define 'value' attribute),
      then need to also define how to delete the selected labels (deleteClick)
    */
    return (
      <div>
        <GenePanelSelector list={panelNames} onChange={this.handleGenePanelChange} value={panelName} />
        <Divider hidden />
        <Button as={Link} to="/gene/new">Create New Gene Panel</Button>
        <Divider />
        <h3>Select which genes have been detected</h3>
        <GenesSelector
          onChange={this.handlePresentChange}
          title={primary.title}
          list={geneList.gene1}
          color={geneState.present.color}
          selected={selected}
          deleteClick={this.handleDeleteClick}
          value={panelName}
          name={primary.name}
        />
        <GenesSelector
          onChange={this.handlePresentChange}
          title={additional.title}
          list={geneList.gene2}
          color={geneState.present.color}
          selected={selected}
          deleteClick={this.handleDeleteClick}
          name={additional.name}
        />
        <GenesSelector
          onChange={this.handlePresentChange}
          title={chr.title}
          list={geneList.gene3}
          color={geneState.present.color}
          selected={selected}
          deleteClick={this.handleDeleteClick}
          name={chr.name}
        />
      </div>
    );
  }
}

ConnectedGenesTable.propTypes = {
  onGeneChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  updateGenesTable: PropTypes.func.isRequired,
  genePanel: PropTypes.shape({
    mutations: PropTypes.shape({}),
    checkbox: PropTypes.shape({}),
  }).isRequired,
  genesTable: PropTypes.shape({
    panelName: PropTypes.string,
    geneList: PropTypes.shape({
      gene1: PropTypes.arrayOf(PropTypes.shape({})),
      gene2: PropTypes.arrayOf(PropTypes.shape({})),
      gene3: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  input: PropTypes.shape({}).isRequired,
};

const GenesTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedGenesTable);

export default GenesTable;
