import React from 'react';
import { Container } from 'semantic-ui-react';
import '../../css/copyrightbanner.css';
import { Link } from 'react-router-dom';
import LotBanner from './LotBanner';

import { copyrightNotice } from '../../constants/Config';

function CopyrightBanner() {
  // Set height to 'single' to occupy a single line or 'double' for twice the height
  const height = 'double';
  return (
    <div>
      <Container textAlign="center" className={`copyrightbanner ${height}`} id="copyrightbanner">
        { copyrightNotice } | {' '}
        <Link
          to="/legal/privacy"
          className="copyrightlink"
          id="copyrightPrivacy"
        >
          Site Privacy
        </Link> | {' '}
        <Link to="/legal/disclaimer" className="copyrightlink" id="copyrightDisclaimer">Terms of Use</Link>
        <LotBanner />
      </Container>
    </div>
  );
}

export default CopyrightBanner;
