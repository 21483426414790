import React from 'react';

// Remove check on line length for these constants
/* eslint-disable max-len */
export const MPNPublication = 'https://www.nejm.org/doi/10.1056/NEJMoa1716614';
export const MPNPublicationFull = `Classification and Personalized Prognosis in Myeloproliferative Neoplasms.
Jacob Grinfeld*, Jyoti Nangalia*, E. Joanna Baxter,David C. Wedge, Nicos Angelopoulos, Robert Cantrill, Anna L. Godfrey, Elli Papaemmanuil, Gunes Gundem, Cathy MacLean,Julia Cook, Laura O’Neil, Sarah O’Meara, Jon W. Teague, Adam P. Butler, Charlie E. Massie, Nicholas Williams, Francesca L. Nice, Christen L. Andersen, Hans C. Hasselbalch,Paola Guglielmelli, Mary F. McMullin, Alessandro M. Vannucchi, Claire N. Harrison, Moritz Gerstung, Anthony R. Green*, and Peter J. Campbell*.
N Engl J Med 2018; 379:1416-1430 DOI: 10.1056/NEJMoa1716614`;

export const onlineDIPSSTool = 'https://www.mdcalc.com/dipss-dynamic-international-prognostic-scoring-system-myelofibrosis#evidence';

export const sangerURL = 'https://www.sanger.ac.uk';
export const sangerName = 'Genome Research Limited (operating as the Wellcome Sanger Institute)';
export const sangerAddress = 'Wellcome Genome Campus, Hinxton, Cambridgeshire CB10 1SA, UK';
export const mpnvoiceURL = 'https://www.mpnvoice.org.uk/';
export const bloodcancerURL = 'https://bloodcancer.org.uk/';
export const predictbreastURL = 'https://breast.predict.nhs.uk/';
export const predictprostateURL = 'https://prostate.predict.nhs.uk/';
export const casmURL = 'https://www.sanger.ac.uk/programme/cancer-ageing-and-somatic-mutation/';
export const NangaliaURL = 'https://www.sanger.ac.uk/group/nangalia-group/';
export const SangerGenomicsInnovationURL = 'https://www.sanger.ac.uk/non_science_group/technology-translation/';
export const wintoncentreURL = 'https://wintoncentre.maths.cam.ac.uk/';
export const wintoncentreEmail = 'wintoncentre@maths.cam.ac.uk';
export const coxhdURL = 'https://github.com/mg14/CoxHD';
export const mg14URL = 'https://github.com/mg14/mg14';

export const medicalDeviceRegulation = 'This device fulfils the provision of the UK Medical Devices Regulations 2002 (SI 2002 No 618, as amended)';

export const medicalDevices = [
  {
    header: 'IEC 62304:2006 Amd 1:2015',
    item: 'Medical device software - Software lifecycle processes.',
  },
  {
    header: 'ISO 14971:2021',
    item: 'Medical Devices - Application of risk management to medical devices.',
  },
  {
    header: 'Directive 93/42/EEC',
    item: 'European Council Directive concerning medical devices.',
  },
  {
    header: 'MEDDEV 2.1/1 rev.9',
    item: 'Classification of Medical Devices',
  },
  {
    header: 'UK Medical Devices Regulations 2002 (SI 2002 No 618, as amended)',
    item: 'UK Government - Medical Device Regulations',
  },
];

/* eslint-enable max-len */

export const predictBloodEmail = 'predictblood@sanger.ac.uk';
export const feedbackButton = {
  body: 'How would you rate the Predict Blood site (from 1 to 5 stars):\n\nAny other comments?\n',
  subject: 'MPN prognostic model',
};

export const copyrightNotice = 'Copyright © 2023 Wellcome Sanger Institute. All Rights Reserved';

export const connectError = `Failure to connect to the Predict Blood service. Please contact ${predictBloodEmail}`;

// User message banner
export const s3Url = 'https://cog.sanger.ac.uk';
export const s3Bucket = 'predictblood-messages';
export const s3File = 'usermessage.json';
export const timeIntervalInMinutes = 30;
export const userMessageColor = 'red';
export const validSegmentColors = [
  'red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple',
  'pink', 'brown', 'grey', 'black',
];

// export const borderColor = '#d45e00';
// Same of header color in styles.css
export const borderColor = 'rgb(175, 5, 5)';

export const plotColours = {
  blue: 'rgba(31, 119, 180, 0.5)',
  orange: 'rgba(255, 127, 14, 0.5)',
  green: 'rgba(44, 160, 44, 0.5)',
  red: 'rgba(214, 39, 40, 0.5)',
  purple: 'rgba(148, 103, 189, 0.5)',
  grey: 'rgab(214, 227, 222, 1)',
};

// Specific labels for Chronic Phase
export const cpPlotAttribs = [
  { label: 'Death in Chronic Phase', color: plotColours.blue, key: 'y.deathInCpFromCp' },
  { label: 'Death in MF', color: plotColours.orange, key: 'y.deathInMfFromCp' },
  { label: 'Death in post-CP AML', color: plotColours.green, key: 'y.deathInAmlFromCp' },
  { label: 'Death in post-MF AML', color: plotColours.red, key: 'y.deathInMfamlFromCp' },
  { label: 'Alive in MF', color: plotColours.purple, key: 'y.aliveInMfFromCp' },
];

// Specific labels for MF
// Additional white space required to keep the plotly plot area the same
// for plots with different initialDiagnoses (MF has different legend labels)
export const mfPlotAttribs = [
  { label: 'Death in MF', color: plotColours.orange, key: 'y.deathInMfFromMF' },
  { label: 'Death in post-MF AML                  ', color: plotColours.red, key: 'y.deathInAmlFromMF' },
];

export const cpPlotBackground = {
  label: 'Alive in ET/PV (Chronic Phase, CP)',
  colour: '#D6E3DE',
};

export const mfPlotBackground = {
  label: 'Alive in MF',
  colour: plotColours.purple,
};

export const outcomes = {
  OS: {
    title: 'Overall Survival',
    text: '{0} out of {1} patients would be alive at {2} years',
  },
  AML: {
    title: 'Acute Myeloid Leukemia',
    text: '{0} out of {1} patients would have progressed to AML at {2} years',
  },
  MF: {
    title: 'Myelofibrosis',
    text: '{0} out of {1} patients would have progressed to Myelofibrosis at {2} years',
  },
};

export const yearText = {
  5: 'fiveyr',
  10: 'tenyr',
  20: 'twentyyr',
};

export const mutationGroups = {
  primary: {
    title: 'Primary pathogenic variants',
    type: 'gene1',
    name: 'PrimaryMutations',
  },
  additional: {
    title: 'Additional pathogenic variants',
    type: 'gene2',
    name: 'AdditionalMutations',
  },
  chr: {
    title: 'Chromosomal changes',
    type: 'gene3',
    name: 'ChrAbnormalities',
  },
};

export const geneState = {
  present: {
    value: '1',
    color: 'green',
  },
  absent: {
    value: '0',
    color: 'red',
  },
  unknown: {
    value: 'NA',
  },
};

// Ordered genelist
export const geneList = [
  'JAK2',
  'CALR',
  'MPL',
  'JAK2e12',
  'TET2',
  'ASXL1',
  'DNMT3A',
  'PPM1D',
  'EZH2',
  'NF1',
  'NFE2',
  'SF3B1',
  'SRSF2',
  'TP53',
  'U2AF1',
  'CBL',
  'MLL3',
  'ZRSR2',
  'GNAS',
  'KRAS',
  'SH2B3',
  'IDH2',
  'PTPN11',
  'KIT',
  'RB1',
  'SETBP1',
  'BCOR',
  'NRAS',
  'CUX1',
  'STAG2',
  'IDH1',
  'FLT3',
  'RUNX1',
  'PHF6',
  'GATA2',
  'MBD1',
  'GNB1',
  'Chr1p',
  'Chr1q',
  'Chr4',
  'Chr5',
  'Chr7',
  'Chr8',
  'Chr9p',
  'Chr9',
  'Chr11',
  'Chr12',
  'Chr13',
  'Chr14',
  'Chr17',
  'Chr18',
  'Chr19',
  'Chr20'];

export const diagnoses = ['ET', 'PV', 'MF', 'OM'];

export const requiredPatientInputFields = [
  'initialDiagnosis',
  'Age',
  'Hb',
  'WCC',
  'Pl',
  'Sex',
  'PriorThrom',
  'Splen',
];

/*
HACK can I use these here?
const diagnosisOptions = [
  { key: 'et', text: 'Essential Thrombocyotosis', value: 'ET' },
  { key: 'pv', text: 'Polycythemia Vera', value: 'PV' },
  { key: 'mf', text: 'Primary/Secondary Myelofibrosis', value: 'MF' },
  { key: 'om', text: 'Other MPN', value: 'OM' },
];
*/

export const initialDiagnosisElement = {
  name: 'initialDiagnosis',
  default: 'NA',
  type: 'radio',
  label: 'Initial diagnosis',
  buttons: [
    {
      label: 'ET',
      value: 'ET',
    },
    {
      label: 'PV',
      value: 'PV',
    },
    {
      label: 'MF',
      value: 'MF',
    },
    {
      label: 'Other',
      value: 'OM',
    },
  ],
};

export const formValues = {
  patientID: {
    default: '',
    type: 'text',
    label: 'Patient ID',
  },
  initialDiagnosis: initialDiagnosisElement,
  Age: {
    default: NaN,
    type: 'number',
    label: <><span className="avoid_wrap">Age at diagnosis</span><span className="avoid_wrap">(range 18-100)</span></>,
    min: 18,
    max: 100,
    step: 1,
  },
  Hb: {
    default: NaN,
    type: 'number',
    label: 'Haemoglobin (g/l) (range 50-220 g/l)',
    min: 50,
    max: 220,
    step: 1,
  },
  WCC: {
    default: NaN,
    type: 'number',
    label: 'White cell count (x10⁹/l) (range 1-100x10⁹/l))',
    min: 1,
    max: 100,
    step: 1,
  },
  Pl: {
    default: NaN,
    type: 'number',
    label: 'Platelet count (x10⁹/l) (range 10-3000x10⁹/l)',
    min: 10,
    max: 3000,
    step: 100,
  },
  Sex: {
    default: '',
    type: 'radio',
    label: 'Sex',
    buttons: [
      {
        label: 'Female',
        value: '1',
      },
      {
        label: 'Male',
        value: '2',
      },
      {
        label: 'Unknown',
        value: 'NA',
      },
    ],
  },
  PriorThrom: {
    default: '',
    type: 'radio',
    label: 'Prior thrombosis',
    buttons: [
      {
        label: 'No',
        value: '0',
      },
      {
        label: 'Yes',
        value: '1',
      },
      {
        label: 'Unknown',
        value: 'NA',
      },
    ],
  },
  Splen: {
    default: '',
    type: 'radio',
    label: 'Splenomegaly',
    buttons: [
      {
        label: 'Present',
        value: '1',
      },
      {
        label: 'Absent',
        value: '0',
      },
      {
        label: 'Unknown',
        value: 'NA',
      },
    ],
  },
  JAK2: { default: 'NA', type: 'gene1', label: 'JAK2V617F', required: true },
  CALR1: { default: 'NA', type: 'gene1', label: 'CALR p.L367fs*46 (Type1 or similar)', required: true },
  CALR2: { default: 'NA', type: 'gene1', label: 'CALR p.K385fs*47 (Type2 or similar)', required: true },
  MPL: { default: 'NA', type: 'gene1', label: 'MPL', required: true },
  JAK2e12: { default: 'NA', type: 'gene1', label: 'JAK2 exon 12', required: true },
  ASXL1: { default: 'NA', type: 'gene2', label: 'ASXL1', required: true },
  TET2: { default: 'NA', type: 'gene2', label: 'TET2', required: true },
  DNMT3A: { default: 'NA', type: 'gene2', label: 'DNMT3A', required: true },
  PPM1D: { default: 'NA', type: 'gene2', label: 'PPM1D' },
  EZH2: { default: 'NA', type: 'gene2', label: 'EZH2', required: true },
  NF1: { default: 'NA', type: 'gene2', label: 'NF1' },
  NFE2: { default: 'NA', type: 'gene2', label: 'NFE2', required: true },
  SF3B1: { default: 'NA', type: 'gene2', label: 'SF3B1', required: true },
  SRSF2: { default: 'NA', type: 'gene2', label: 'SRSF2', required: true },
  TP53: { default: 'NA', type: 'gene2', label: 'TP53', required: true },
  U2AF1: { default: 'NA', type: 'gene2', label: 'U2AF1', required: true },
  CBL: { default: 'NA', type: 'gene2', label: 'CBL', required: true },
  MLL3: { default: 'NA', type: 'gene2', label: 'MLL3' },
  ZRSR2: { default: 'NA', type: 'gene2', label: 'ZRSR2', required: true },
  GNAS: { default: 'NA', type: 'gene2', label: 'GNAS' },
  KRAS: { default: 'NA', type: 'gene2', label: 'KRAS', required: true },
  SH2B3: { default: 'NA', type: 'gene2', label: 'SH2B3', required: true },
  IDH2: { default: 'NA', type: 'gene2', label: 'IDH2', required: true },
  PTPN11: { default: 'NA', type: 'gene2', label: 'PTPN11' },
  KIT: { default: 'NA', type: 'gene2', label: 'KIT', required: true },
  RB1: { default: 'NA', type: 'gene2', label: 'RB1' },
  SETBP1: { default: 'NA', type: 'gene2', label: 'SETBP1', required: true },
  BCOR: { default: 'NA', type: 'gene2', label: 'BCOR' },
  NRAS: { default: 'NA', type: 'gene2', label: 'NRAS', required: true },
  CUX1: { default: 'NA', type: 'gene2', label: 'CUX1', required: true },
  STAG2: { default: 'NA', type: 'gene2', label: 'STAG2' },
  IDH1: { default: 'NA', type: 'gene2', label: 'IDH1', required: true },
  FLT3: { default: 'NA', type: 'gene2', label: 'FLT3' },
  RUNX1: { default: 'NA', type: 'gene2', label: 'RUNX1', required: true },
  PHF6: { default: 'NA', type: 'gene2', label: 'PHF6' },
  GATA2: { default: 'NA', type: 'gene2', label: 'GATA2' },
  MBD1: { default: 'NA', type: 'gene2', label: 'MBD1' },
  GNB1: { default: 'NA', type: 'gene2', label: 'GNB1' },
  C1p: { default: 'NA', type: 'gene3', label: '1pLOH' },
  C1q: { default: 'NA', type: 'gene3', label: '1q+' },
  C4: { default: 'NA', type: 'gene3', label: '4qLOH' },
  C5: { default: 'NA', type: 'gene3', label: '5q-' },
  C7: { default: 'NA', type: 'gene3', label: '7q-' },
  C8: { default: 'NA', type: 'gene3', label: 'Tri 8' },
  C9U: { default: 'NA', type: 'gene3', label: '9pLOH' },
  C9g: { default: 'NA', type: 'gene3', label: 'Tri 9' },
  C11: { default: 'NA', type: 'gene3', label: '11q-' },
  C12: { default: 'NA', type: 'gene3', label: '12pLOH' },
  C13: { default: 'NA', type: 'gene3', label: '13qLOH' },
  C14: { default: 'NA', type: 'gene3', label: '14qLOH' },
  C17: { default: 'NA', type: 'gene3', label: '17p' },
  C18: { default: 'NA', type: 'gene3', label: '18qLOH' },
  C19: { default: 'NA', type: 'gene3', label: '19pLOH' },
  C20: { default: 'NA', type: 'gene3', label: '20q-' },
};

// MPN algorithm returns CALR_Type_1 rather than CALR1
export const formValueSynonyms = {
  CALR_Type_1: 'CALR1',
  CALR_Type_2: 'CALR2',
  '1pUPD': 'C1p',
  '4qUPD': 'C4',
  '9pUPD': 'C9U',
  '12pUPD': 'C12',
  '13pUPD': 'C13',
  '14qUPD': 'C14',
  '18qUPD': 'C18',
  '19pUPD': 'C19',

};

// Minimal genes required
// KRAS, NRAS, TP53, JAK2, CALR, MPL, ASXL1, CBL, CHEK2, CSF3R, CUX1, DNMT3A, EZH2, IDH1,
// IDH2, IKZF1, KIT, NFE2, SF3B1, SH2B3, SRSF2, TET2, U2AF1, HRAS, RUNX1, SETBP1, ZRSR2
// we have no CHEK2, CSF3R IKZF1 HRAS

const ge = '\u2265';
const le = '\u2264';
export const dipssValues = {
  dAge: {
    default: NaN,
    type: 'radio',
    label: 'Age, years',
    conversion: 1,
    operand: 65,
    values: [0, 1],
    operator: 'le',
    buttons: [
      {
        label: `${le} 65`,
        value: 0,
      },
      {
        label: '>65',
        value: 1,
      },
    ],
  },
  dWCC: {
    default: NaN,
    type: 'radio',
    label: 'White cell count (x10⁹/l)',
    conversion: 1,
    operand: 25,
    operator: 'le',
    values: [0, 1],
    buttons: [
      {
        label: `${le} 25`,
        value: 0,
      },
      {
        label: '>25',
        value: 1,
      },
    ],
  },
  dHb: {
    default: NaN,
    type: 'radio',
    label: 'Haemoglobin g/dL',
    conversion: 10,
    operand: 10,
    operator: 'ge',
    values: [0, 2],
    buttons: [
      {
        label: `${ge} 10`,
        value: 0,
      },
      {
        label: '<10',
        value: 2,
      },
    ],
  },
  dPBB: {
    default: NaN,
    type: 'radio',
    label: 'Peripheral blood blasts',
    conversion: null,
    buttons: [
      {
        label: '<1%',
        value: 0,
      },
      {
        label: `${ge} 1%`,
        value: 1,
      },
    ],
  },
  dCS: {
    default: NaN,
    type: 'radio',
    label: 'Constitutional symptoms',
    conversion: null,
    buttons: [
      {
        label: 'No',
        value: 0,
      },
      {
        label: 'Yes',
        value: 1,
      },
    ],
  },
  total: {
    default: NaN,
  },
};

export const DIPSSScore = [
  {
    min: 0,
    max: 0,
    risk: 'Low',
    median: 'Median survival not reached in original studies',
  },
  {
    min: 1,
    max: 2,
    risk: 'Intermediate-1',
    median: '14.2 years',
  },
  {
    min: 3,
    max: 4,
    risk: 'Intermediate-2',
    median: '4 years',
  },
  {
    min: 5,
    max: 6,
    risk: 'High',
    median: '1.5 years',
  },
];
