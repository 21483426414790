import update from 'immutability-helper';

import {
  UPDATE_COHORT_INPUT,
  RESET_COHORT_INPUT,
} from '../actions/types';

import {
  formValues,
} from '../constants/Config';

function initialiseState() {
  const result = {
    initialDiagnosis: formValues.initialDiagnosis.default,
    patientID: formValues.patientID.default,
  };
  return result;
}

function cohortInput(
  state = initialiseState(),
  action = {}) {
  // console.log('reducer cohortInput', action);
  // console.log('state=', state);
  switch (action.type) {
    case UPDATE_COHORT_INPUT:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_COHORT_INPUT:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default cohortInput;
