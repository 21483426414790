import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import GenesTable from './GenesTable';
import FormRows from './FormRows';
import ModalInformation from './ModalInformation';

import {
  formValues,
} from '../constants/Config';

import ModalData from '../constants/HelpText';
import '../css/newpatientform.css';

function NewPatientForm({ input, onChange, validation, genePanel, onGeneChange }) {
  const rows = FormRows(formValues, input, onChange, validation);
  const name = 'MolecularData';
  return (
    <Grid columns={2} stackable divided>
      <Grid.Column>
        <h2>Information about the patient</h2>
        <Grid columns={2}>
          {rows}
        </Grid>
      </Grid.Column>
      <Grid.Column>
        <Grid columns={2}>
          <Grid.Column width={1}>
            <ModalInformation header={ModalData[name].Header} content={ModalData[name].Content} />
          </Grid.Column>
          <Grid.Column width={14}>
            <h2>Information about genetic tests</h2>
          </Grid.Column>
        </Grid>
        <h3>Select which genes have been tested</h3>
        <GenesTable input={input} onChange={onChange} genePanel={genePanel} onGeneChange={onGeneChange} />
      </Grid.Column>
    </Grid>
  );
}

NewPatientForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onGeneChange: PropTypes.func.isRequired,
  input: PropTypes.shape({}).isRequired,
  validation: PropTypes.shape({}).isRequired,
  genePanel: PropTypes.shape({}).isRequired,
};

export default NewPatientForm;
