/*
 * Location of api url
 */
export function getApiUrl() {
  let { apiUrl } = '';

  // When running locally, use environment variable
  if (process.env.REACT_APP_API_URL) {
    apiUrl = process.env.REACT_APP_API_URL;
  }

  // Try from url and append histology-api path
  if (!apiUrl) {
    apiUrl = `${window.location.origin}/predictblood-api`;
  }

  if (!apiUrl) {
    throw new Error('apiUrl is not set, either from window or process.env');
  }
  // console.log('apiUrl=', apiUrl, ' location=', window.location);
  return apiUrl;
}

/*
 * Find field validation depending on the element type
 */
export function fieldValidation(element, value) {
  let isValid = false;

  if (element.type === 'number') {
    // Check value within min and max
    if (value >= element.min && value <= element.max) {
      isValid = true;
    }
  } else if (element.type === 'radio') {
    // Clicked any radio button
    isValid = true;
  } else if (element.type === 'text') {
    if (value) {
      isValid = true;
    }
  }
  return isValid;
}

/*
 * Validate the input (semantic-ui-react form does not support this)
 */
export function isValidForm(fields, validation) {
  let isValid = true;
  fields.forEach((field) => {
    if (!validation[field]) {
      isValid = false;
    }
  });
  return isValid;
}

/*
  Parse to get: year, outcome, percentage
  Examples:
  5yr OS: 96.5%
  5yr AML risk: 0.1%
  5yr MF risk: 0.8%
*/
export function parseRisk(risk) {
  const regex = /(\d+)yr (\w+).*: (\d*.*\d*)%/;
  const match = risk.match(regex);
  const riskFactors = {
    year: match[1],
    outcome: match[2],
    percentage: match[3],
  };
  return riskFactors;
}

// template: '{0} text {1} text {2} text'
export function format(template, ...args) {
  return template.replace(/{(\d+)}/g, (match, number) => args[number]);
}

export default getApiUrl;
