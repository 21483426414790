import React from 'react';
import PropTypes from 'prop-types';

import OverviewPage from '../components/about/OverviewPage';

function AboutPage({ banner, headerBand }) {
  return (
    <div>
      { headerBand }
      <OverviewPage />
      { banner }
    </div>
  );
}

AboutPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default AboutPage;
