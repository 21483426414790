import {
  SET_LOADING,
} from '../actions/types';

function loading(
  state = false,
  action = {}) {
  switch (action.type) {
    case SET_LOADING:
      return action.value;
    default:
      return state;
  }
}

export default loading;
