import React from 'react';

import { Segment, Header, Button, Grid, Message, Divider, Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import localStore from 'store';
import PropTypes from 'prop-types';

import GenePanelSelector from '../../components/genePanel/GenePanelSelector';
import GenePanel from '../../components/genePanel/GenePanel';
import GridCheckbox from '../../components/GridCheckbox';
import Help from '../../components/Help';

import {
  formValues,
  mutationGroups,
} from '../../constants/Config';

import ModalData from '../../constants/HelpText';

function createGenePanelChecks(genePanel, type) {
  const rows = [];

  Object.keys(formValues).forEach((name) => {
    const gene = formValues[name];
    if (gene.type === type) {
      rows.push(
        <GridCheckbox label={gene.label} name={name} checked={genePanel[name]} key={name} readOnly />,
      );
    }
  });
  return rows;
}

class GenePanelView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      panelName: '',
    };
  }

  // Choose panel from dropdown
  handleChange = (e, { value }) => {
    this.setState({ panelName: value });
  };

  render() {
    const { banner } = this.props;
    const { panelName } = this.state;
    const { primary, additional, chr } = mutationGroups;
    const storedGenePanels = localStore.get('prblGenePanel');
    const panelNames = [];

    let checks1 = [];
    let checks2 = [];
    let checks3 = [];
    if (panelName) {
      checks1 = createGenePanelChecks(storedGenePanels[panelName], primary.type);
      checks2 = createGenePanelChecks(storedGenePanels[panelName], additional.type);
      checks3 = createGenePanelChecks(storedGenePanels[panelName], chr.type);
    }

    if (storedGenePanels) {
      Object.keys(storedGenePanels).forEach((panel) => {
        panelNames.push({ key: panel, value: panel, text: panel });
      });
    }

    return (
      <div>
        { banner }
        <Container className="topbotmargin">
          <Help help={ModalData.genePanelView}>
            <Header as="h1">View Gene Panel</Header>
          </Help>
          { storedGenePanels ? (
            <div>
              <Grid>
                <Grid.Column width={8}>
                  <GenePanelSelector list={panelNames} onChange={this.handleChange} />
                </Grid.Column>
              </Grid>
            </div>
          ) : (
            <div>
              <Message info compact>No panels currently stored</Message>
              <Divider hidden />
              <Button as={Link} to="/gene/new">Create New Gene Panel</Button>
            </div>
          ) }
          { panelName
            ? (
              <Segment>
                <GenePanel checkboxes={checks1} title={primary.title} />
                <GenePanel checkboxes={checks2} title={additional.title} />
                <GenePanel checkboxes={checks3} title={chr.title} />
              </Segment>
            )
            : <div /> }
        </Container>
      </div>
    );
  }
}

GenePanelView.propTypes = {
  banner: PropTypes.element.isRequired,
};

export default GenePanelView;
