import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Media } from './Media';
import MPNPlotDesktop from './MPNPlotDesktop';
import MPNPlotMobile from './MPNPlotMobile';
import { setInitialDiagnosis } from './PatientDescription';
import {
  cpPlotAttribs,
  mfPlotAttribs,
  cpPlotBackground,
  mfPlotBackground,
} from '../constants/Config';

function createAnnotation(annots, x, inputY) {
  const plotText = [];
  let y = inputY;
  annots.forEach((row) => {
    plotText.push(
      {
        x,
        y,
        xref: 'x',
        yref: 'y',
        text: row,
        showarrow: false,
      },
    );
    y -= 0.05;
  });
  return plotText;
}

function MPNPlot({ multiRFX5, print }) {
  const results = [];

  // Return if no data
  if (_.isEmpty(multiRFX5)) {
    return (<div />);
  }

  // console.log('MULTIRFX5', multiRFX5);
  // First element is the graph structure

  const mpn = multiRFX5.plot;
  // console.log('mpn=', mpn);

  // Initialise plot attributes and background
  let names = cpPlotAttribs;
  let bgAttribs = cpPlotBackground;

  // If inititalDiagnosis is MF, reassign names and bgColour
  const initialDiagnosis = setInitialDiagnosis(multiRFX5.patientData);
  if (initialDiagnosis === 'MF') {
    names = mfPlotAttribs;
    bgAttribs = mfPlotBackground;
  }

  const numCols = names.length;

  // map key to names index
  const mapKeyToIndex = {};
  for (let i = 0; i < numCols; i++) {
    mapKeyToIndex[names[i].key] = i;
  }

  for (let i = 0; i < numCols; i++) {
    results[i] = {
      y: [],
      x: [],
      stackgroup: 'one',
      name: names[i].label,
      hovertemplate: '%{y}<extra></extra>',
      fillcolor: names[i].color,
      line: { color: names[i].color },
    };
  }

  mpn.forEach((row) => {
    // console.log('row=', row);
    Object.entries(row).forEach(([key, value]) => {
      // console.log('key=', key, value);
      if (key !== 'x') {
        const column = mapKeyToIndex[key];
        results[column].y.push(value);
        results[column].x.push(row.x);
      }
    });
  });

  const line = {
    x: [],
    y: [],
    mode: 'lines',
    line: { color: 'black' },
    showlegend: false,
    width: 3 };

  multiRFX5.line.forEach((row) => {
    line.x.push(row.x);

    // get on same scale as plots
    line.y.push(row.y - 1);
  });
  results.push(line);

  // console.log('traces=', newtraces);
  // console.log('results=', results);
  const annotations = [];

  // HACK should have this for all callbacks
  // if (multiRFX5.fiveyr) {
  // spread operator will pass the elements of createAnnotation to push
  annotations.push(...createAnnotation(multiRFX5.fiveyr, (3.5 * 365) / 25, -0.8));
  annotations.push(...createAnnotation(multiRFX5.tenyr, (10.5 * 365) / 25, -0.8));
  annotations.push(...createAnnotation(multiRFX5.twentyyr, (17.5 * 365) / 25, -0.8));
  annotations.push(...createAnnotation(multiRFX5.MEFS, (12.5 * 365) / 25, -0.4));
  // }

  // Add item to legend for background
  const background = {
    x: [0],
    y: [0],
    name: bgAttribs.label,
    stackgroup: 'one',
    fillcolor: bgAttribs.colour,
    line: {
      color: bgAttribs.colour,
    },
    showlegend: true,
  };
  results.push(background);

  // const x_mult = 12/25;
  const xMult = 365.25 / 25;
  const layout = {
    yaxis: {
      range: [-1, 0],
      tickvals: [-1, -0.75, -0.5, -0.25, 0],
      ticktext: [0.0, 0.25, 0.5, 0.75, 1.0],
      title: 'Proportion of patients',
    },
    xaxis: {
      range: [0, 20 * xMult],
      title: 'Time from diagnosis (years)',
      tickvals: [0, 5 * xMult, 10 * xMult, 15 * xMult, 20 * xMult],
      ticktext: [0, 5, 10, 15, 20],
    },
    title: 'MPN Personalised Risk Calculator',
    plot_bgcolor: bgAttribs.colour,
    hovermode: false,
  };

  /*
    If printing, then use hard-coded values for height and width
    rather taking from the screen size which does not rescale when
    on a mobile
  */
  // Carefully crafted width so that the plot tests still work when the legend text was updated
  // Changes in the legend text will change the plot size
  const plotWidth = '868px';
  let style = {};
  if (print) {
    style = {
      width: plotWidth,
      height: '720px',
    };
  } else {
    style = {
      width: `min(100vw, ${plotWidth})`,
      height: 'calc(min(100vw, 800px) * 0.9)',
    };
  }

  // If printing, always use desktop style
  return (
    print === true ? (
      <MPNPlotDesktop results={results} layout={layout} style={style} annotations={annotations} print />
    ) : (
      <div>
        <Media lessThan="computer">
          <MPNPlotMobile results={results} layout={layout} style={style} annotations={annotations} />
        </Media>
        <Media greaterThan="tablet">
          <MPNPlotDesktop results={results} layout={layout} style={style} annotations={annotations} print={false} />
        </Media>
      </div>
    )
  );
}

MPNPlot.propTypes = {
  multiRFX5: PropTypes.shape({
    plot: PropTypes.arrayOf(PropTypes.shape({})),
    line: PropTypes.arrayOf(PropTypes.shape({})),
    fiveyr: PropTypes.arrayOf(PropTypes.string),
    tenyr: PropTypes.arrayOf(PropTypes.string),
    twentyyr: PropTypes.arrayOf(PropTypes.string),
    MEFS: PropTypes.arrayOf(PropTypes.string),
    patientData: PropTypes.shape({
      age: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      hb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      mutations: PropTypes.arrayOf(PropTypes.string),
      mutationsAbsent: PropTypes.arrayOf(PropTypes.string),
      patientID: PropTypes.arrayOf(PropTypes.string),
      platelet: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      sex: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
      wcc: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    }),
  }).isRequired,
  print: PropTypes.bool.isRequired,
};

export default MPNPlot;
