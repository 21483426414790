import React from 'react';
import { Image, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import imageryIcon from '../images/predict_blood_tool_banner.png';

import '../css/bannercontainer.css';

function PredictBloodToolBanner({ text }) {
  return (
    <div className="outercontainer small">
      <div id="innerbox">
        <div id="transparentbox">
          <Grid columns={2} doubling padded>
            <Grid.Column width={6} id="predictbloodtoolbannercontainer">
              <Image src={imageryIcon} size="medium" centered />
            </Grid.Column>
            <Grid.Column width={10} verticalAlign="middle">
              <p style={{ fontSize: '20px' }}>
                { text }
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
  );
}

PredictBloodToolBanner.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PredictBloodToolBanner;
