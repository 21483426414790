import React from 'react';
import { Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import styles from '../css/styles.module.css';

function PatientOutcome({ multiRFX5 }) {
  // Return if no data
  if (_.isEmpty(multiRFX5)) {
    return (<div />);
  }

  return (
    <div className={styles.noshow}>
      <Message>
        <Message.Header>Patient Outcomes</Message.Header>
        <p>
          {multiRFX5.MEFS}
        </p>
      </Message>
    </div>
  );
}

PatientOutcome.propTypes = {
  multiRFX5: PropTypes.shape({
    MEFS: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default PatientOutcome;
