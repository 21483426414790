import React from 'react';
import { Confirm, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import localStore from 'store';

import DisclaimerNotice from './legal/DisclaimerNotice';

class DisclaimerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  // Set whether the modal is shown
  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  handleConfirm = (e) => {
    const { onSave } = this.props;
    e.preventDefault();

    // Set localStorage variable
    localStore.set('prblDisclaimer', true);

    // Run the parent handleSubmit command
    onSave();
  };

  // Called from PredictBloodForm handleModal function
  /* eslint-disable react/no-unused-class-component-methods */
  onSubmit = () => {
    const { onSave } = this.props;
    const readDisclaimer = localStore.get('prblDisclaimer');
    // If read the disclaimer, submit the results
    if (readDisclaimer) {
      onSave();
    } else {
      // If not read the disclaimer, then open the modal
      this.open();
    }
  };
  /* eslint-enable react/no-unused-class-component-methods */

  render() {
    const { open } = this.state;
    const message = (
      <Segment basic>
        <DisclaimerNotice />
      </Segment>
    );

    return (
      <Confirm
        open={open}
        header="Important Message"
        content={message}
        onCancel={this.close}
        onConfirm={(e) => { this.handleConfirm(e); this.close(); }}
      />
    );
  }
}

DisclaimerModal.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default DisclaimerModal;
