import React from 'react';

import CollabBanner from './CollabBanner';
import CopyrightBanner from './CopyrightBanner';

function Footer() {
  return (
    <div>
      <CollabBanner />
      <CopyrightBanner />
    </div>
  );
}

export default Footer;
