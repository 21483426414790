import React from 'react';
import { List } from 'semantic-ui-react';

function ReleaseNotesList({ releases, size }) {
  // Disable this check as this list is static and cannot change dynamically
  /* eslint-disable react/no-array-index-key */
  return (
    releases.map((release) => (
      <div key={release.version}>
        <h2>
          Release {release.version}
        </h2>
        <List bulleted size={size}>
          {release.notes.map((item, index) => (
            <List.Item key={index}>{item}</List.Item>
          ))}
        </List>
        <div />
      </div>
    ))
  );
  /* eslint-enable react/no-array-index-key */
}

export default ReleaseNotesList;
