import React from 'react';
import { Message } from 'semantic-ui-react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  formValues,
  formValueSynonyms,
} from '../constants/Config';

import { multiRFX5PropTypes } from '../utils/PropTypeValues';

function findIsKaryotype(mutations) {
  let isKaryotype = false;
  // Split on , and remove any whitespace
  const mutationList = mutations.split(/\s*,\s*/);
  const mutMap = mutationList.reduce((obj, key) => ({ ...obj, [key]: true }), {});

  Object.keys(formValues).forEach((name) => {
    if (mutMap[formValues[name].label]) {
      if (formValues[name].type === 'gene3') {
        isKaryotype = true;
      }
    }
  });
  return isKaryotype;
}

function mutationLabel(mutations) {
  const labels = [];
  const notFound = {};
  mutations.split(',').forEach((mutation) => {
    const name = mutation.trim();
    if (name in formValues) {
      labels.push(formValues[name].label);
    } else if (name in formValueSynonyms) {
      labels.push(formValues[formValueSynonyms[name]].label);
    } else {
      // gene3 keys are returned as labels
      notFound[name] = true;
    }
  });
  if (notFound) {
    Object.keys(formValues).forEach((name) => {
      const { label } = formValues[name];
      if (notFound[label]) {
        labels.push(label);
      }
    });
  }
  return labels;
}

export function setInitialDiagnosis(patientData) {
  let initialDiagnosis = 'Other';
  if (patientData.ET[0] === 1) {
    initialDiagnosis = 'ET';
  } else if (patientData.PV[0] === 1) {
    initialDiagnosis = 'PV';
  } else if (patientData.MF[0] === 1) {
    initialDiagnosis = 'MF';
  }
  return initialDiagnosis;
}

function PatientDescription({ multiRFX5, patientID }) {
  // console.log('PatientDescription', multiRFX5);
  // Return if no data
  if (_.isEmpty(multiRFX5)) {
    return (<div />);
  }

  const data = multiRFX5.patientData;
  const gender = { 1: 'F', 2: 'M' };
  const priorThrom = { 0: 'No', 1: 'Yes', NA: 'Unknown' };
  const splen = { 0: 'Absent', 1: 'Present', NA: 'Unknown' };

  const initialDiagnosis = setInitialDiagnosis(data);

  let patientSelected = '';
  // cohort data will have multiRFX5.patientData.patientID but not patientInput.patientID
  if (patientID) {
    patientSelected = patientID;
  } else if (data.patientID) {
    [patientSelected] = data.patientID;
  }

  // See if any karyotype elements (mutations or mutationsAbsent)
  let karyotype = findIsKaryotype(multiRFX5.patientData.mutations[0]);
  if (!karyotype) {
    karyotype = findIsKaryotype(multiRFX5.patientData.mutationsAbsent[0]);
  }

  // Convert mutations into label values
  const mutations = mutationLabel(data.mutations[0]);

  return (
    <Message>
      <Message.Header>Patient Description</Message.Header>
      <p>
        <b>Patient ID:</b> {patientSelected}
        <br />
        <b>Initial Diagnosis:</b> {initialDiagnosis}
        <b> Age:</b>{data.age}.
        <b> Gender:</b> {gender[data.sex]}.
        <b> Haemoglobin:</b> {data.hb} g/l.
        <b> White cell count:</b> {data.wcc} x10<sup>9</sup>/l.
        <b> Platelet count:</b> {data.platelet} x10<sup>9</sup>/l.
        <b> Prior Thrombosis:</b> {priorThrom[data.priorThrom]}.
        <b> Splenomegaly:</b> {splen[data.splen]}.
        <br />
        <b>Variants detected:</b> {mutations.join(', ')}
        <br />
        <b>Karyotype available:</b> {karyotype ? 'yes' : 'no'}
      </p>
    </Message>
  );
}

PatientDescription.propTypes = {
  multiRFX5: multiRFX5PropTypes.isRequired,
  patientID: PropTypes.string,
};

PatientDescription.defaultProps = {
  patientID: '',
};
export default PatientDescription;
