import React from 'react';
import { Grid, Header, Message, Container } from 'semantic-ui-react';
import localStore from 'store';
import PropTypes from 'prop-types';

import GenePanelSelector from '../../components/genePanel/GenePanelSelector';
import ConfirmDelete from '../../components/ConfirmDelete';
import Help from '../../components/Help';
import ModalData from '../../constants/HelpText';

class GenePanelDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      panelName: '',
    };
  }

  // Choose panel from dropdown
  handleChange = (e, { value }) => {
    this.setState({ panelName: value });
  };

  // Press Delete button
  handleDelete = () => {
    const { panelName } = this.state;

    // Retrieve current contents from localStorage
    const storedGenePanels = localStore.get('prblGenePanel');

    // Delete entry
    delete storedGenePanels[panelName];

    // Store again
    localStore.set('prblGenePanel', storedGenePanels);
    this.setState({ panelName: '' });
  };

  render() {
    const { banner } = this.props;
    const { panelName } = this.state;
    const storedGenePanels = localStore.get('prblGenePanel');
    const panelNames = [];
    let header = '';
    if (storedGenePanels) {
      Object.keys(storedGenePanels).forEach((panel) => {
        panelNames.push({ key: panel, value: panel, text: panel });
      });
    }

    let disabled = true;
    if (panelName) {
      disabled = false;
      header = `Delete panel "${panelName}"`;
    }
    return (
      <div>
        { banner }
        <Container className="topbotmargin">
          <Help help={ModalData.genePanelDelete}>
            <Header as="h1">Delete Gene Panel</Header>
          </Help>
          { storedGenePanels ? (
            <Grid>
              <Grid.Column width={8}>
                <GenePanelSelector list={panelNames} onChange={this.handleChange} />
                <ConfirmDelete onDelete={this.handleDelete} isDisabled={disabled} header={header} />
              </Grid.Column>
            </Grid>
          ) : (
            <div>
              <Message info compact>No panels currently stored</Message>
            </div>
          ) }
        </Container>
      </div>
    );
  }
}

GenePanelDelete.propTypes = {
  banner: PropTypes.element.isRequired,
};

export default GenePanelDelete;
