import PropTypes from 'prop-types';

export const DIPSSPropTypes = PropTypes.shape({
  Age: PropTypes.number,
  CS: PropTypes.number,
  Hb: PropTypes.number,
  PBB: PropTypes.number,
  WCC: PropTypes.number,
  total: PropTypes.number,
});

export const DIPSSValidationPropTypes = PropTypes.shape({
  Age: PropTypes.bool,
  CS: PropTypes.bool,
  Hb: PropTypes.bool,
  PBB: PropTypes.bool,
  WCC: PropTypes.bool,
  total: PropTypes.bool,
});

export const multiRFX5PropTypes = PropTypes.shape({
  patientData: PropTypes.shape({
    age: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    hb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    mutations: PropTypes.arrayOf(PropTypes.string),
    mutationsAbsent: PropTypes.arrayOf(PropTypes.string),
    patientID: PropTypes.arrayOf(PropTypes.string),
    platelet: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    sex: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    wcc: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  }),
});
