import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Message, Container } from 'semantic-ui-react';

import CohortDataForm from './CohortDataForm';
import PredictBloodResults from './PredictBloodResults';
import { multiRFX5PropTypes } from '../utils/PropTypeValues';

import '../css/styles.css';

const mapStateToProps = (state) => ({
  multiRFX5: state.multiRFX5Cohort,
});

export function ConnectedCohortDataPage({ multiRFX5, banner }) {
  return (
    <div>
      {banner}
      <Container className="topbotmargin">
        <h1>
          Cohort Data
        </h1>
        <Message>
          <p className="ptext">
            Select from the options below to see the predictions generated for the patients in the initial cohort.
          </p>
        </Message>
        <CohortDataForm />
        <PredictBloodResults multiRFX5={multiRFX5} displayDisclaimer={false} />
      </Container>
    </div>
  );
}

ConnectedCohortDataPage.propTypes = {
  multiRFX5: multiRFX5PropTypes.isRequired,
  banner: PropTypes.element.isRequired,
};

const CohortDataPage = connect(mapStateToProps)(ConnectedCohortDataPage);

export default CohortDataPage;
