import React from 'react';
import PropTypes from 'prop-types';

import { Media } from '../Media';
import ResponsiveMenuMobile from './ResponsiveMenuMobile';
import ResponsiveMenuDesktop from './ResponsiveMenuDesktop';

class ResponsiveMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({ visible: !prevState.visible }));
  };

  render() {
    const { leftItems } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <Media lessThan="computer">
          <ResponsiveMenuMobile
            leftItems={leftItems}
            onToggle={this.handleToggle}
            visible={visible}
          />
        </Media>

        <Media greaterThan="tablet">
          <ResponsiveMenuDesktop leftItems={leftItems} />
        </Media>
      </div>
    );
  }
}

ResponsiveMenu.propTypes = {
  leftItems: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ResponsiveMenu;
