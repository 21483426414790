import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ResetButton from './ResetButton';
import HelpInfoText from './HelpInfoText';

function ResetHelpInfoText({ onClick }) {
  return (
    <Grid columns={2} doubling>
      <Grid.Column width={2}>
        <ResetButton onClick={onClick} />
      </Grid.Column>
      <Grid.Column width={12}>
        <HelpInfoText />
      </Grid.Column>
    </Grid>
  );
}

ResetHelpInfoText.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ResetHelpInfoText;
