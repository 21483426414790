import update from 'immutability-helper';

import {
  CREATE_PATIENT_INPUT,
  RESET_PATIENT_INPUT,
  UPDATE_PATIENT_INPUT,
} from '../actions/types';

import {
  formValues,
} from '../constants/Config';

function initialiseState() {
  const result = {};
  Object.keys(formValues).forEach((elem) => {
    result[elem] = formValues[elem].default;
  });
  return result;
}

function patientInput(
  state = initialiseState(),
  action = {}) {
  // console.log('reducer patientInput', action);
  // console.log('state=', state);
  switch (action.type) {
    case CREATE_PATIENT_INPUT:
      return update(state, {
        $set: action.input,
      });
    case UPDATE_PATIENT_INPUT:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_PATIENT_INPUT:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default patientInput;
