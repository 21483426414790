import React from 'react';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import '../../css/styles.css';

import bookIcon from '../../images/book-icon.png';

function FindOutMore() {
  return (
    <div>
      <Image src={bookIcon} style={{ marginTop: '10px' }} />
      <h2 id="findoutmore">
        Where can I find out more?
      </h2>
      <p className="ptext">To read more go to
        <Link to="/about"> About Predict Blood.</Link>
      </p>
    </div>
  );
}

export default FindOutMore;
