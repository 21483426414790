import React from 'react';
import { Form } from 'semantic-ui-react';
// import PropTypes from 'prop-types';

import ButtonRow from './ButtonRow';
import IncrementInput from './IncrementInput';
import GridLabeledInput from './GridLabeledInput';
import ModalInformation from './ModalInformation';

import ModalData from '../constants/HelpText';

/*
 * formValues: list of elements, indexed by 'name'
 * input: current input value, indexed by 'name'
 * onChange: event handler
 * validation: validation state (boolean), indexed by 'name'
 */
function FormRows(formValues, input, onChange, validation) {
  const rows = [];
  let i = 0;
  const labelWidth = 5;
  const componentWidth = 10;

  Object.keys(formValues).forEach((name) => {
    let key;
    const elem = formValues[name];

    if (elem.type === 'text') {
      key = `text${i}`;
      // console.log('key', key);
      rows.push(
        <GridLabeledInput
          key={name}
          help={<ModalInformation header={ModalData[name].Header} content={ModalData[name].Content} />}
          label={elem.label}
          labelWidth={labelWidth}
          component={(
            <Form.Input
              placeholder={name}
              name={name}
              value={input[name]}
              onChange={onChange}
              type="text"
              key={key}
            />
          )}
          componentWidth={componentWidth}
        />,
      );
    } else if (elem.type === 'number') {
      key = `number${i}`;
      // console.log('key', key);
      const showBorder = !validation[name];
      rows.push(
        <GridLabeledInput
          key={key}
          help={<ModalInformation header={ModalData[name].Header} content={ModalData[name].Content} />}
          label={elem.label}
          labelWidth={labelWidth}
          component={(
            <Form.Field
              placeholder={name}
              name={name}
              value={input[name]}
              onChange={onChange}
              control={IncrementInput}
              showBorder={showBorder}
              setValue={(e, value) => onChange(e, { name, value })}
              maxValue={elem.max}
              minValue={elem.min}
              step={elem.step}
              classname={name}
              key={i}
            />
          )}
          componentWidth={componentWidth}
        />,
      );
    } else if (elem.type === 'radio') {
      key = `radio${i}`;
      const showBorder = !validation[name];
      // Find which button is currently active
      let currValue = '';
      elem.buttons.forEach((button) => {
        if (input[name] === button.value) {
          currValue = button.label;
        }
      });
      rows.push(
        <GridLabeledInput
          key={key}
          help={<ModalInformation header={ModalData[name].Header} content={ModalData[name].Content} />}
          label={elem.label}
          labelWidth={labelWidth}
          component={(
            <Form.Field
              placeholder={name}
              name={name}
              value={currValue}
              onChange={onChange}
              control={ButtonRow}
              showBorder={showBorder}
              setValue={(e, value) => onChange(e,
                { name, value: elem.buttons.filter((item) => item.label === value)[0].value })}
              buttonList={elem.buttons.map((item) => item.label)}
              classname={name}
              key={key}
            />
          )}
          componentWidth={componentWidth}
        />,
      );
    }
    i += 1;
  });
  return rows;
}

export default FormRows;
