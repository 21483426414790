import React from 'react';
import { Dropdown, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ModalData from '../constants/HelpText';
import Help from './Help';

const renderSelectionLabel = (label, color) => ({
  color,
  content: label.text,
  // style: { borderRadius: '.28571429rem' },
  style: { borderRadius: '4px' },
});

/* eslint-disable react/prefer-stateless-function */
class GenesSelector extends React.Component {
  render() {
    const { list, onChange, color, title, selected, deleteClick, name } = this.props;
    let numItems = 0;
    if (list) {
      numItems = list.length;
    }
    return (
      <div>
        <Help help={ModalData[name]} width={16}>
          <Header as="h3" name="geneselector">{title} ({numItems})</Header>
        </Help>
        <Dropdown
          placeholder="Select which variants the patient has"
          fluid
          multiple
          search
          selection
          options={list}
          onChange={onChange}
          renderLabel={(label) => renderSelectionLabel(label, color)}
          value={selected}
          onMouseDown={deleteClick}
        />
      </div>
    );
  }
}

GenesSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  deleteClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  })),
};

GenesSelector.defaultProps = {
  list: [],
};

export default GenesSelector;
