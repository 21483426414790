import React from 'react';
import PropTypes from 'prop-types';
import Border from './Border';
import { borderColor } from '../constants/Config';

class ButtonRow extends React.Component {
  handleClick = (label, e) => {
    const { setValue } = this.props;
    setValue(e, label);
  };

  render() {
    const { buttonList, name, showBorder, value } = this.props;
    const activeColor = 'blue';

    const buttonElements = buttonList.map((entry) => (
      <button key={entry} id={`textButton${entry}`} className={entry === value ? `ui button ${name} ${activeColor} active` : 'ui button'} type="button" onClick={(e) => this.handleClick(entry, e)}>
        {entry}
      </button>
    ));

    return (
      <div className="ui input">
        <Border isVisible={showBorder} color={borderColor} classname={name}>
          <div className="ui fluid buttons">
            {buttonElements}
          </div>
        </Border>
      </div>
    );
  }
}

ButtonRow.propTypes = {
  buttonList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValue: PropTypes.func.isRequired,
  showBorder: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ButtonRow;
