import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

function ResetButton({ onClick }) {
  return (
    <Button onClick={onClick} name="reset">Reset</Button>
  );
}

ResetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ResetButton;
