import React from 'react';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import DIPSSResults from './DIPSSResults';
import FormRows from './FormRows';
import { DIPSSPropTypes, DIPSSValidationPropTypes } from '../utils/PropTypeValues';

import {
  dipssValues,
} from '../constants/Config';

function DIPSSForm(props) {
  const { DIPSS, onChange, DIPSSValidation } = props;
  const rows = FormRows(dipssValues, DIPSS, onChange, DIPSSValidation);
  return (
    <div>
      <Grid columns={2} stackable divided>
        <Grid.Column>
          <Grid columns={2}>
            {rows}
          </Grid>
        </Grid.Column>
      </Grid>
      <DIPSSResults DIPSS={DIPSS} DIPSSValidation={DIPSSValidation} show />
    </div>
  );
}

DIPSSForm.propTypes = {
  input: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  DIPSS: DIPSSPropTypes.isRequired,
  DIPSSValidation: DIPSSValidationPropTypes.isRequired,
};

export default DIPSSForm;
