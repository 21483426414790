import update from 'immutability-helper';

import {
  UPDATE_DIPSS,
  RESET_DIPSS,
} from '../actions/types';

import {
  dipssValues,
} from '../constants/Config';

function initialiseState() {
  const result = {};
  Object.keys(dipssValues).forEach((elem) => {
    result[elem] = dipssValues[elem].default;
  });
  return result;
}

function DIPSS(
  state = initialiseState(),
  action = {}) {
  switch (action.type) {
    case UPDATE_DIPSS:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_DIPSS:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default DIPSS;
