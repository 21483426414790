import React, { useLayoutEffect } from 'react';
import { Container, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MPNPublication, MPNPublicationFull } from '../../constants/Config';

import '../../css/styles.css';

function TechnicalPage({ banner, headerBand }) {
  // Scroll to top of page when linking
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1 id="technical">
          Technical
        </h1>
        <p className="ptext">
          Predict Blood was based on a group of 2035 patients: 1321 patients with essential thrombocythemia,
          356 with polycythemia vera, 309 with myelofibrosis, and 49 with other myeloproliferative neoplasms who
          were part of a research study that investigated how variations in the genetic code of MPNs affected how
          their disease progressed. The researchers chose these patients as they had high-quality clinical
          information and test results. The patients were predominantly UK based and were followed up over a period
          of many years in order to see what happened to them, and this information allowed the original
          algorithm underlying the Predict Blood model to be constructed; a way of estimating what might happen to a
          new patient given what happened to these past patients.
          <br /><br />
          The model behind Predict Blood is a multistate survival model incorporating 63 clinical and genomic
          variables, that estimates
          a patient’s probability of transition between stages (or outcomes) of disease. The different outcomes were:
        </p>
        <List ordered>
          <List.Item className="ptext">survival in chronic-phase disease
            (essential thrombocythemia, ET or polycythaemia vera, PV)
          </List.Item>
          <List.Item className="ptext">death in chronic-phase disease (essential
            thrombocythemia, ET or polycythaemia vera, PV)
          </List.Item>
          <List.Item className="ptext">survival in advanced-phase disease (myelofibrosis, MF)
          </List.Item>
          <List.Item className="ptext">death in advanced-phase disease (myelofibrosis, MF) and
          </List.Item>
          <List.Item className="ptext">acute myeloid leukaemia (AML)
          </List.Item>
        </List>
        <p className="ptext">
          It was constructed by combining individual cox proportional hazard models
          for the different possible transitions between disease states, Chronic Phase
          (CP-  ET, PV, and MPN-unspecified, MPNu) to death, MF to death, CP to MF, either CP or MF to AML,
          with patients censored if an outcome other that the outcome of interest for each transition occurred.
          <br /><br />
          Variables can have different impact on each of the different transitions. For example, genomic features
          play a substantial role in predicting progression from chronic-phase disease to myelofibrosis and
          to acute leukaemia transformation.
          In contrast, a major cause of death in chronic phase disease was old age.
          <br /><br />
          The model was internally validated using a “leave one out” method: estimates for each patient were
          generated using data from the remaining patients in the initial cohort. External validation was performed
          on 417 patients from Italy. Accuracy of estimates for each disease transition in the internal
          and external validation was assessed using multiple statistical measures of concordance - overall the
          model was found to have made accurate predictions 80% of the time.
          <br /><br />
          Genomic variables are entered into Predict Blood as either present or absent, because mutation clone size,
          measured as a proportion of total tumour clone size in the sample, was not found to impact on survival or
          disease progression.
          When an additional variable specifying if the mutation clone size was large or small was included in
          Predict Blood the accuracies of the estimates were unchanged.
          <br /><br />
          The model does not take account treatments given to a patient and is not intended to be used to determine
          treatment decisions.
        </p>
        <p className="ptext">
          This calculator is intended as an adjunct to the paper and for information purposes only.
          It has been tested on other groups of patients to check the estimates that it generated were accurate.
          Whilst Predict Blood produces good estimates, it cannot say exactly what will happen to a single individual.
          It provides a likelihood of different outcomes for individuals. It has been not been prospectively
          validated - that means that we have not used it to estimate what might happen to a group of current patients
          and waited many years to see how accurate its estimates were. Data regarding the accuracy of the model are
          provided in the publication.
          <br /><br />
          Use the <a href="#publications">Publications</a> link below to access the full publication and supplementary
          material for further information on the development of the model.
        </p>
        <h3>Predict Blood Tool</h3>
        <p className="ptext">
          To generate individual patient prognostic estimates:
        </p>
        <List bulleted>
          <List.Item className="ptext">Choose the PredictBloodTool tab</List.Item>
          <List.Item className="ptext">
            Select the diagnosis of interest: ET, PV, MF or other (MPNu, MDS/MPN overlap, etc).
          </List.Item>
          <List.Item className="ptext">From the left hand panel, input all mandatory values</List.Item>
          <List.Item className="ptext">From the right hand panel, input any driver mutations</List.Item>
          <List.Item className="ptext">
            Please click on the <b>Estimate outcome</b> button to generate estimates.
          </List.Item>
        </List>
        <p className="ptext">
          Please note that changes in input parameters will only reflect in the estimates once you have clicked
          the <b>Estimate outcome</b> button.
        </p>
        <p className="ptext">
          If you need to generate a downloadable report, please make sure that you click
          on <b>Estimate outcome</b> for the selected patient before you generate a report for that particular patient.
        </p>
        <p className="ptext">
          Outcome estimates are from diagnosis and assume that all input parameters are present at diagnosis.
          If the time of genomic sampling is after diagnosis then we suggest adjusting the patient age to their age at
          the time of genomic sampling, and to use this as the starting time for estimates.
        </p>
        <h3>Cohort Data</h3>
        <p className="ptext">
          Select a patient used in the algorithm analysis to view their clinical and genomic parameters,
          predicted and actual outcomes.
        </p>
        <h3>Cohort Mutations</h3>
        <p className="ptext">
          This tab allows the user to view the frequency of mutations(s) across MPN subtypes in the original study.
        </p>
        <h2 id="history">
          Website development history
        </h2>
        <p className="ptext">
          The first website as referenced by the  {' '}
          <a href={MPNPublication} target="_blank" rel="noreferrer noopener">publication</a> was an
          <a
            href="https://cancer.sanger.ac.uk/mpn-multistage/"
            target="_blank"
            rel="noreferrer noopener"
          > R shiny application
          </a><br />
          The current website is written in React and provides a more robust implementation of the same model.
        </p>
        <p className="ptext">
          Please see
          <Link to="/about"> About Predict Blood </Link>
          for more information about how the model was developed.
        </p>
        <h2 id="publications">
          Publications
        </h2>
        <List ordered>
          <List.Item className="ptext">
            { MPNPublicationFull }
            <a
              href={MPNPublication}
              target="_blank"
              rel="noreferrer noopener"
            >[Full paper online]
            </a>
          </List.Item>
        </List>
        <p className="ptext">(* shared authorship)</p>
      </Container>
      { banner }
    </div>
  );
}

TechnicalPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default TechnicalPage;
