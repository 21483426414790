import {
  ADD_USER_MESSAGE,
} from '../actions/types';

function userMessage(state = {}, action = {}) {
  switch (action.type) {
    case ADD_USER_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}

export default userMessage;
