import React from 'react';
import PropTypes from 'prop-types';

function Border(props) {
  const { children, isVisible, color, classname } = props;

  return (
    isVisible === true ? (
      <div className={`ui input ${classname}`} style={{ border: `3px solid ${color}`, borderRadius: 5, padding: 2 }}>
        {children}
      </div>
    ) : (
      <div className={`ui input ${classname}`} style={{ padding: 5 }}>
        {children}
      </div>
    )
  );
}

Border.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  classname: PropTypes.string.isRequired,
};

export default Border;
