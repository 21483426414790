import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import {
  geneList,
} from '../constants/Config';

import {
  fetchTGSgenes as actionFetchTGSgenes,
  updateGenomicsInput as actionUpdateGenomicsInput,
} from '../actions';

import TGSgenesPlot from '../components/TGSgenesPlot';
import TGSgenesText from '../components/TGSgenesText';

const mapStateToProps = (state) => ({
  TGSgenes: state.TGSgenes,
  genomicsInput: state.genomicsInput,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTGSgenes: (data) => dispatch(actionFetchTGSgenes(data)),
  createGenomicsInput: () => dispatch(actionUpdateGenomicsInput()),
  updateGenomicsInput: (name, value) => dispatch(actionUpdateGenomicsInput(name, value)),
});

export class ConnectedGenomicsPlot extends React.Component {
  constructor(props) {
    super(props);

    // Initial state for dropdown lists
    this.state = {
      gene1: geneList[0],
      gene2: geneList[0],
    };
  }

  componentDidMount() {
    const { fetchTGSgenes } = this.props;
    const { gene1, gene2 } = this.state;

    const data = {
      Gene1: gene1,
      Gene2: gene2,
    };

    // Fetch histogram data for initial state
    fetchTGSgenes(data);
  }

  handleChange = (e, { name, value }) => {
    const { gene1, gene2 } = this.state;
    const { fetchTGSgenes } = this.props;
    // console.log('Updating input=', e);
    // console.log('key=', name, value);

    // Set the state
    this.setState({ [name]: value });

    // HACK Is there a better way of doing this?
    let data = {};
    if (name === 'Gene1') {
      data = {
        Gene1: value,
        Gene2: gene2 };
    } else {
      data = {
        Gene1: gene1,
        Gene2: value };
    }
    // console.log('data=', data);

    // Fetch the histogram data on change
    fetchTGSgenes(data);
  };

  render() {
    const { TGSgenes } = this.props;
    const { gene1, gene2 } = this.state;

    // console.log('Gene1', gene1, gene2);
    // console.log('TGSgenes', TGSgenes);

    // Create structure for dropdown menu
    const geneNames = [];
    geneList.forEach((gene) => {
      const elem = {
        key: gene,
        text: gene,
        value: gene,
      };
      geneNames.push(elem);
    });

    return (
      <div>
        <Dropdown
          placeholder="Gene1"
          name="gene1"
          search
          selection
          options={geneNames}
          defaultValue={geneNames[0].value}
          onChange={this.handleChange}
        />
        <Dropdown
          placeholder="Gene2"
          name="gene2"
          search
          selection
          options={geneNames}
          defaultValue={geneNames[0].value}
          onChange={this.handleChange}
        />
        <Segment>
          <h3>{gene1}</h3>
          <TGSgenesText TGSgene={TGSgenes.gene1} gene={gene1} />
        </Segment>
        <Segment>
          <h3>{gene2}</h3>
          <TGSgenesText TGSgene={TGSgenes.gene2} gene={gene2} />
        </Segment>
        <TGSgenesPlot TGSgenes={TGSgenes} gene1={gene1} gene2={gene2} />
      </div>
    );
  }
}

ConnectedGenomicsPlot.propTypes = {
  fetchTGSgenes: PropTypes.func.isRequired,
  TGSgenes: PropTypes.shape({
    plot: PropTypes.arrayOf(PropTypes.shape({
      Diagnosis: PropTypes.string,
      Gene: PropTypes.string,
      Percentage: PropTypes.number,
    })),
    gene1: PropTypes.shape({}),
    gene2: PropTypes.shape({}),
  }),
};

ConnectedGenomicsPlot.defaultProps = {
  TGSgenes: {},
};

const GenomicsPlot = connect(mapStateToProps, mapDispatchToProps)(ConnectedGenomicsPlot);
export default GenomicsPlot;
