import React, { useLayoutEffect } from 'react';
import { Container } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import '../../css/styles.css';
import ReleaseNotesList from './ReleaseNotesList';

/*
 * Add new release information to the top of the list
 */

// Remove check on line length for these messages
/* eslint-disable max-len */
const releases = [
  {
    version: '1.0.1',
    notes: [
      'Fixed issue with MF plot legend labels',
    ],
  },
  {
    version: '1.0.0',
    notes: [
      'Initial public release',
    ],
  },
];
/* eslint-enable max-len */

function ReleaseNotesPage({ banner, headerBand }) {
  // Scroll to top of page when linking
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      { headerBand }
      <Container className="topbotmargin">
        <h1 id="releasenotes">
          Release Notes
        </h1>
        <ReleaseNotesList releases={releases} size="big" />
      </Container>
      { banner }
    </div>
  );
}

ReleaseNotesPage.propTypes = {
  banner: PropTypes.element.isRequired,
  headerBand: PropTypes.element.isRequired,
};

export default ReleaseNotesPage;
