import React from 'react';
import PropTypes from 'prop-types';

function Mailto({ email, subject = '', body = '', children }) {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  return <a href={`mailto:${email}${params}`} target="_blank" rel="noopener noreferrer">{children}</a>;
}

Mailto.propTypes = {
  email: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Mailto.defaultProps = {
  subject: '',
  body: '',
};

export default Mailto;
