import React from 'react';
import { Image } from 'semantic-ui-react';
import '../../css/copyrightbanner.css';

import lotIcon from '../../images/lot2.png';
import ukcaIcon from '../../images/UKCA_white_fill.png';

const version = process.env.REACT_APP_VERSION;

function LotBanner() {
  return (
    <div>
      <Image src={lotIcon} width="40px" centered verticalAlign="middle" /> <span>v{version}</span>
      <Image src={ukcaIcon} width="40px" spaced="left" />
    </div>
  );
}

export default LotBanner;
