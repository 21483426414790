import {
  ADD_TGSGENESINFO,
} from '../actions/types';

function TGSgenesInfo(state = '', action = {}) {
  switch (action.type) {
    case ADD_TGSGENESINFO:
      return action.payload;
    default:
      return state;
  }
}

export default TGSgenesInfo;
