import update from 'immutability-helper';

import {
  UPDATE_DIPSS_VALIDATION,
  RESET_DIPSS_VALIDATION,
} from '../actions/types';

import {
  dipssValues,
} from '../constants/Config';

function initialiseState() {
  const result = {};
  Object.keys(dipssValues).forEach((key) => {
    result[key] = false;
  });
  return result;
}

function DIPSSValidation(
  state = initialiseState(),
  action = {}) {
  switch (action.type) {
    case UPDATE_DIPSS_VALIDATION:
      return update(state, {
        [action.name]: { $set: action.value },
      });
    case RESET_DIPSS_VALIDATION:
      return update(state, {
        $set: initialiseState(),
      });
    default:
      return state;
  }
}

export default DIPSSValidation;
