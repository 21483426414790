import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';
import _ from 'lodash';

function TGSgenesPlot({ TGSgenes, gene1, gene2 }) {
  const traces = [];

  // console.log('start TGSgenesPlot');

  // Return if no data
  if (_.isEmpty(TGSgenes)) {
    return (<div />);
  }

  // console.log('TGSgenes=', TGSgenes);
  // console.log('PLOT1 gene1=', gene1, gene2);

  // HACK better way of doing this?
  const type1 = `${gene1} alone`;
  const type2 = `${gene2} alone`;

  // Loop round each row
  TGSgenes.plot.forEach((row) => {
    // console.log('row=', row);
    // console.log('diagnosis=', row.Diagnosis);

    /*
      If gene1 is not the same as gene2:
      Give index to each gene
      0 => gene1 alone
      1 => gene1+gene2
      2 => gene2 alone
    */
    let index = 1;
    if (gene1 !== gene2) {
      if (row.Gene === type1) {
        index = 0;
      } else if (row.Gene === type2) {
        index = 2;
      }
    } else {
      // Gene1 is the same as Gene2
      index = 0;
    }

    // console.log('index=', index);
    // Initialise structure
    if (!(traces[index])) {
      // console.log('initialising traces');
      traces[index] = {
        x: [],
        y: [],
        type: 'bar',
        name: row.Gene,
      };
    }

    traces[index].x.push(row.Diagnosis);
    traces[index].y.push(row.Percentage);
  });

  const style = {
    width: 'min(100vw, 800px)',
    height: 'calc(min(100vw, 800px) * 0.9)',
  };

  // console.log('traces=', traces);
  const layout = { barmode: 'stack' };
  return (
    <div>
      <Plot
        data={traces}
        layout={layout}
        style={style}
      />
    </div>
  );
}

TGSgenesPlot.propTypes = {
  TGSgenes: PropTypes.shape({
    plot: PropTypes.arrayOf(PropTypes.shape({
      Diagnosis: PropTypes.string,
      Gene: PropTypes.string,
      Percentage: PropTypes.number,
    })),
    gene1: PropTypes.shape({}),
    gene2: PropTypes.shape({}),
  }),
  gene1: PropTypes.string.isRequired,
  gene2: PropTypes.string.isRequired,
};

TGSgenesPlot.defaultProps = {
  TGSgenes: {},
};

export default TGSgenesPlot;
