import React from 'react';
import { Button, Confirm } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class ConfirmDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  open = () => this.setState({ open: true });

  close = () => this.setState({ open: false });

  render() {
    const { onDelete, header, isDisabled } = this.props;
    const { open } = this.state;

    return (
      <div>
        <Button
          type="button"
          onClick={this.open}
          disabled={isDisabled}
          className="ui button primary"
          name="delete"
        >
          Delete
        </Button>
        <Confirm
          open={open}
          onCancel={this.close}
          onConfirm={() => { onDelete(); this.close(); }}
          header={header}
        />
      </div>
    );
  }
}

ConfirmDelete.propTypes = {
  onDelete: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ConfirmDelete;
