import {
  ADD_MPNINPUT,
} from '../actions/types';

function MPNinput(state = [], action = {}) {
  switch (action.type) {
    case ADD_MPNINPUT:
      // Skip the first entry, which is empty
      return action.payload.slice(1);
    default:
      return state;
  }
}

export default MPNinput;
