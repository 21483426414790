import React from 'react';
import { Image, Grid } from 'semantic-ui-react';

import StartButton from './StartButton';
import imageryIcon from '../images/predict_blood_computer_1.png';

import '../css/bannercontainer.css';

function WhatIsPredict() {
  return (
    <div className="outercontainer large">
      <div id="innerbox">
        <Grid columns={2} stackable padded>
          <Grid.Column width={6}>
            <h1 style={{ fontSize: '36px' }}>Predict Blood</h1>
            <p style={{ fontSize: '20px' }}>
              Predict Blood is an online tool to help health professionals
              see how a person&apos;s blood cancer (known as myeloproliferative neoplasm,
              or MPN) might develop given their age, gender, health and any information
              that is known about genetic variations that they carry.
            </p>
            <StartButton />
          </Grid.Column>
          <Grid.Column width={10} id="whatispredictimagecontainer">
            <Image src={imageryIcon} style={{ maxHeight: '400px' }} />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  );
}

export default WhatIsPredict;
